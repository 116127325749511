import React, { useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import BasicLayout from '../layout/basic.layout';
import Home from './home/home.page.';
import Snp from './admin/snp-admin.page';
import Sections from './admin/sections-admin.page';
import SelectedSnp from './admin/selected-snp-admin.page';
import i18n from '../i18n';
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import AdminPage from "./admin/admin.page";
import PopulationDynamicPage from './admin/population-dynamic.page';
import RoadsConditionPage from './admin/roads-condition.page';
import SelfSufficiencyPage from './admin/self-sufficiency.page';
import SERIndicatorsPage from './admin/ser-indicators.page';
import InfrastructurePage from './admin/infrastructure.page';
import TaxPage from './admin/tax.page';
import BudgetPage from './admin/budget.page';
import PopulationDemandPage from './admin/population-demand.page';

export const api = axios.create({
  baseURL: '/api',
});

const RoutesIndex = () => {
  const [params] = useSearchParams()
  const { initialized, keycloak } = useKeycloak();

  api.interceptors.request.use(
    (config) => {
      const token = keycloak.token
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
        config.headers!.credentials = 'include';
      }
      return config;
    },
    (error) => {
      Promise.reject(error)
    },
  );

  api.interceptors.response.use(
    (response) => {
      return response.data ? response.data : response;
    },
    async (error) => {
      const originalRequest = error.config;
      if ([401, 403].includes(error.response?.status)) {
        keycloak.updateToken(600).then(value => {
          if (value) {
            api.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
            return api(originalRequest);
          }
        }).catch(reason => {
          window.location.href = `/`;
          return Promise.reject(error);
        })
      }
      return Promise.reject(error);
    },
  );



  useEffect(() => {
    if (params.get('lang')) {
      i18n.changeLanguage(params.get('lang') as 'kz' | 'ru')
    }

    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }

    return () => {
      if (keycloak) keycloak.onTokenExpired = () => { };
    };
  }, [params, keycloak, initialized])

  return (
    initialized ?
      <Routes>
        <Route path='/' element={<BasicLayout />}>
          <Route index element={<Home />} />
          <Route path='/:kato' element={<Home />} />
          <Route path='/:kato/:link' element={<Home />} />
          <Route path='/:link' element={<Home />} />
        </Route>
        <Route path='/admin' element={<AdminPage />} >
          <Route path='snp' element={<Snp />} />
          <Route path='sections' element={<Sections />} />
          <Route path=':kato' element={<SelectedSnp />}>
            <Route path='roads-condition' element={<RoadsConditionPage />} />
            <Route path='self-sufficiency' element={<SelfSufficiencyPage />} />
            <Route path='ser' element={<SERIndicatorsPage />} />
            <Route path='infrastructure' element={<InfrastructurePage />} />
            <Route path='population-dynamic' element={<PopulationDynamicPage />} />
            <Route path='tax' element={<TaxPage />} />
            <Route path='budget' element={<BudgetPage />} />
            <Route path='population-demand' element={<PopulationDemandPage />} />
          </Route>
        </Route>
      </Routes> : <></>
  )
}

export default RoutesIndex