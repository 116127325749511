import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Routes from './pages';
import { ReactKeycloakProvider } from "@react-keycloak/web";

import './index.scss';
import './i18n';
import { ThemeProvider } from './contexts/theme.context';
import keycloak from "./keycloak/keycloak";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <ReactKeycloakProvider authClient={keycloak}>
      <BrowserRouter>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </BrowserRouter>
    </ReactKeycloakProvider>
);


