import React, { FC, Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Text } from '../../text.component';
import { DownloadRoadIcon, SortIcon } from '../../../assets/icons';
import { IRoadShare, IRoadShareItem, IRoadShareStats } from '../../../interfaces/snp.interface';
import { BasicStatsItem } from '../index.styles';
import { useDebounce } from '../../../hooks/useDebounce';
import { downloadRoadToExcel, searchRoad } from '../../../requests/kato.request';

const StyledTable = styled.table`
  font-family: 'Montserrat', sans-serif;
  max-width: 100%;
  background-color: var(--bg-lighter);
  position: relative;
  border-radius: 0.625rem; 
  overflow-y: auto; // Allow vertical scrolling
  border-collapse: separate;

  svg.close {
    cursor: pointer;
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
  }
`
const StyledTableHeader = styled.thead`
  max-width: 100%;
  position: sticky; // Make the header sticky
  top: -0.625rem; // Stick the header to the top
  background-color: var(--bg-lighter);
  // border-radius: 0.625rem 0.625rem 0 0; // Round the top corners

  tr {
    // border-radius: 0.625rem 0.625rem 0 0; // Round the top corners
  }

  th {
    padding: 0.5625rem 0.275rem;
    text-align: left;
    box-shadow: inset 1px 1px 0 var(--borderGray);
    
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      gap: 0.25rem;
    
      svg {
        cursor: pointer;
      }
    }
  }
`;

const StyledTableBody = styled.tbody`
  max-width: 100%;
  td {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    font-size: 0.75rem;
    font-family: 'Montserrat', sans-serif;
     border-right: 1px solid var(--borderGray); // Add right border
    border-bottom: 1px solid var(--borderGray); // Add bottom border for separation from the body

    > div {
      font-family: 'Montserrat', sans-serif; 
      display: flex;
      justify-content: center;
    }
  }

  .full-row {
    background-color: #9DFD0080;

    td {
      padding: 0.875rem 0 1.25rem 0;
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
    }
  }

  .cells {
    td {
      padding: 0.625rem 0;
      font-family: 'Montserrat', sans-serif;

      &:first-child {
        text-align: center;
      }
    }
  }

  .cells:nth-child(even) {
    background-color: var(--odd-row);
  }

  .cells:nth-child(odd) {
    background-color: var(--bg);
  }
`;

const Filters = styled.div`
  display: flex;
  margin-bottom: 0.625rem;
  gap: 0.625rem;
  align-items: center;
  input {
    flex: 1;
    background-color: var(--bg-gray);
    border: 1px solid var(--borderGray);
    border-radius: 0.625rem;
    padding: 0.5rem;
    vertical-align: middle;
    color: var(--text-color);
    font-size: 0.75rem;
    height: 2.25rem;
    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      color: var(--text-color);
    }
  }

  svg {
    cursor: pointer;
  }
`

const headerKeys = [
  'totalKm', 'totalPrevYearGoodConditionKm', 'totalPrevYearGoodConditionPercentage', 'totalPrevYearSatisfactoryConditionKm',
  'totalPrevYearSatisfactoryConditionPercentage', 'totalPrevYearPoorConditionKm', 'totalPrevYearPoorConditionPercentage',
  'totalCurYearGoodConditionKm', 'totalCurYearGoodConditionPercentage', 'totalCurYearSatisfactoryConditionKm',
  'totalCurYearSatisfactoryConditionPercentage', 'totalCurYearPoorConditionKm', 'totalCurYearPoorConditionPercentage',
]

const bodyKeys = [
  'index', 'roadName', 'totalKm', 'prevYearGoodConditionKm', 'prevYearGoodConditionPercentage',
  'prevYearSatisfactoryConditionKm', 'prevYearSatisfactoryConditionPercentage', 'prevYearPoorConditionKm',
  'prevYearPoorConditionPercentage', 'curYearGoodConditionKm', 'curYearGoodConditionPercentage',
  'curYearSatisfactoryConditionKm', 'curYearSatisfactoryConditionPercentage', 'curYearPoorConditionKm',
  'curYearPoorConditionPercentage', 'ongoingProjects'
]

const Road: FC<{ data: IRoadShare }> = ({ data }) => {
  const { t } = useTranslation();
  const [sortedData, setSortedData] = useState<IRoadShare>(data);
  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedQuery) {
      if (debouncedQuery.length > 0) {
        searchRoad(debouncedQuery).then((res) => {
          setSortedData(res.data)
        })
      }
      else {
        setSortedData(data)
      }
    } else {
      setSortedData(data)
    }

  }, [debouncedQuery, data])

  const handleSort = (key: string) => {
    let direction = 1
    if (key === 'index') {
      direction = sortedData.roadShareStats[0].roadsList[0].index.split('-').pop()! > sortedData.roadShareStats[0].roadsList[1].index.split('-').pop()! ? 1 : -1
    } else if (key === 'roadName' || key === 'ongoingProjects') {
      const prev = sortedData.roadShareStats[0].roadsList[0][key as keyof IRoadShareItem] || ''
      const next = sortedData.roadShareStats[0].roadsList[1][key as keyof IRoadShareItem] || ''
      direction = prev.localeCompare(next) > 0 ? 1 : -1
    } else {
      direction = +sortedData.roadShareStats[0].roadsList[0][key as keyof IRoadShareItem] - +sortedData.roadShareStats[0].roadsList[1][key as keyof IRoadShareItem] > 0 ? 1 : -1
    }

    const arr = data.roadShareStats.map((item: IRoadShareStats) => ({
      ...item,
      roadsList: item.roadsList.sort((a: IRoadShareItem, b: IRoadShareItem) => {
        if (key === 'roadName' || key === 'ongoingProjects') {
          const prev = a[key as keyof IRoadShareItem] || ''
          const next = b[key as keyof IRoadShareItem] || ''
          return direction > 0 ? prev?.localeCompare(next) : next?.localeCompare(prev)
        } else if (key === 'index') {
          return direction > 0 ? +a[key as keyof IRoadShareItem].split('-').pop()! - +b[key as keyof IRoadShareItem].split('-').pop()! : +b[key as keyof IRoadShareItem].split('-').pop()! - +a[key as keyof IRoadShareItem].split('-').pop()!
        } else {
          return direction > 0 ? +a[key as keyof IRoadShareItem] - +b[key as keyof IRoadShareItem] : +b[key as keyof IRoadShareItem] - +a[key as keyof IRoadShareItem]
        }
      })
    }))

    setSortedData({
      ...data,
      roadShareStats: arr
    })
  }

  const handleDownload = () => {
    downloadRoadToExcel(query)
  }

  const TableHeader = (data: IRoadShare) => {
    const headers = [
      [
        {
          title: 'road-table.№',
          colSpan: 1,
          rowSpan: 3
        },
        {
          title: 'road-table.index',
          colSpan: 1,
          rowSpan: 3,
          sortable: true
        },
        {
          title: 'road-table.roadName',
          colSpan: 1,
          rowSpan: 3,
          sortable: true
        },
        {
          title: 'road-table.totalKm',
          colSpan: 1,
          rowSpan: 3,
          sortable: true
        },
        {
          title: 'road-table.2023-result',
          colSpan: 6,
          rowSpan: 1
        },
        {
          title: 'road-table.2024-result',
          colSpan: 6,
          rowSpan: 1
        },
        {
          title: 'road-table.ongoingProjects',
          colSpan: 1,
          rowSpan: 5,
          sortable: true
        },
      ],
      [
        ...['good', 'satisfactory', 'not-satisfactory'].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 2,
          rowSpan: 1
        })),
        ...['good', 'satisfactory', 'not-satisfactory'].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 2,
          rowSpan: 1
        }))
      ],
      [
        ...['km', "%"].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 1,
          rowSpan: 1
        })),
        ...['km', "%"].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 1,
          rowSpan: 1
        })),
        ...['km', "%"].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 1,
          rowSpan: 1
        })),
        ...['km', "%"].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 1,
          rowSpan: 1
        })),
        ...['km', "%"].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 1,
          rowSpan: 1
        })),
        ...['km', "%"].map((item: string) => ({
          title: 'road-table.' + item,
          colSpan: 1,
          rowSpan: 1
        })),
      ],
      [
        {
          title: 'road-table.result',
          colSpan: 3,
          rowSpan: 1
        },
        ...headerKeys.map((key: string) => ({
          title: sortedData[key as keyof IRoadShare] || 0,
          colSpan: 1,
          rowSpan: 1
        })),
      ],
      [
        {
          title: "",
          colSpan: 1,
          rowSpan: 1
        },
        {
          title: 'road-table.satisfactory-percent',
          colSpan: 3,
          rowSpan: 1
        },
        {
          title: sortedData.prevYearTotalFineRoadPercentage || 0,
          colSpan: 6,
          rowSpan: 1
        },
        {
          title: sortedData.curYearTotalFineRoadPercentage || 0,
          colSpan: 6,
          rowSpan: 1
        },
      ],
    ]

    return (<StyledTableHeader>
      {headers.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((cell: any, cellIndex) => (
            <th key={cellIndex} colSpan={cell.colSpan} rowSpan={cell.rowSpan}>
              <Text fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={700} color="var(--text-color)">
                {t(cell.title)}
                {cell.sortable && <SortIcon onClick={() => handleSort(cell.title.split('.')[1])} height={10} />}
              </Text>
            </th>
          ))}
        </tr>
      ))}
    </StyledTableHeader>)
  }

  const TableBody = (data: IRoadShare) => (
    <StyledTableBody>
      {
        sortedData.roadShareStats.map((item: IRoadShareStats, index) => (
          <Fragment key={index}>
            <tr className='full-row'>
              <td colSpan={1}><Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={700}> {index + 1}</Text></td>
              <td colSpan={16}>
                <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={700}> {item.name}</Text>
              </td >
            </tr>

            {
              item.roadsList.map((road: IRoadShareItem, roadIndex) => (
                <tr className='cells'>
                  <td>
                    <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                      {roadIndex + 1}
                    </Text>
                  </td>
                  {bodyKeys.map((key: string, keyIndex: number) => (
                    <td key={keyIndex}>
                      <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                        {road[key as keyof IRoadShareItem] ? isNaN(+road[key as keyof IRoadShareItem]) || ['roadName', 'ongoingProjects', 'index'].includes(key)
                          ? road[key as keyof IRoadShareItem]
                          : (+road[key as keyof IRoadShareItem]).toFixed(1) : '-'}
                      </Text>
                    </td>
                  ))}
                </tr>
              ))
            }

            <tr className='cells'>
              <td colSpan={3}>
                <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                  Всего
                </Text>
              </td>
              {headerKeys.map((key: string, keyIndex: number) => (
                <td colSpan={1} key={keyIndex}>
                  <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                    {(item as any)[key as keyof IRoadShareStats]}
                  </Text>
                </td>
              ))}
              <td>
                <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                  -
                </Text>
              </td>
            </tr>

            <tr className="cells">
              <td colSpan={4}>
                <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                  Доля дорог в хорошем и удовлетворительном состоянии
                </Text>
              </td>
              <td colSpan={6}>
                <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                  {item.totalPrevYearSatisfactoryConditionPercentage}
                </Text>
              </td>
              <td colSpan={6}>
                <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                  {item.totalCurYearSatisfactoryConditionPercentage}
                </Text>
              </td>
              <td>
                <Text color="var(--text-color)" fontSize="0.625rem" lineHeight="0.914375rem" fontWeight={400}>
                  -
                </Text>
              </td>
            </tr>
          </Fragment>
        ))
      }
    </StyledTableBody>
  )

  return (
    <>
      <Filters>
        <input
          type="text"
          placeholder={'Поиск'}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <DownloadRoadIcon onClick={handleDownload} height={'1.5rem'} />
      </Filters>
      <BasicStatsItem>
        {data && <div className="body" style={{ padding: 0 }}>
          <StyledTable style={{ borderCollapse: 'collapse' }}>
            {TableHeader(data)}
            {TableBody(data)}
          </StyledTable>
        </div>}
      </BasicStatsItem >
    </>

  )
}

export default Road