import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BasicStats from './basic-stats.component'
import DistrictIncome from './district-income.component'
import FoodContainer from './fullscreen/food-container.component'
import PSDFullScreen from './fullscreen/PSD-fullscreen.component'
import Road from './fullscreen/road-fullscreen.component'
import Header from './header.component'
import InvestProjects from './invest-projects.component'
import MediaMonitoring from './media-monitoring.component'
import PopulationDemand from './population-demand.component'
import PopulationDynamic from './population-dynamic.component'
import PSD from './PSD.component'
import ThemeContext, { Theme } from '../../contexts/theme.context'
import { BasicStatsContainer, Filters, RightMenuContainer } from './index.styles'
import {
  IBudgetItem,
  IDistrictIncomeList,
  IMarker,
  IPopulationDemand,
  IPopulationDetails,
  IPopulationDynamic,
  IProductSufficiencyItem,
  IPSDItem,
  IRoadShare,
  ITaxStat,
} from '../../interfaces/snp.interface'
import { navLinks } from '../../constants/navlinks.constant'
import { getGeneralInfo } from '../../requests/snp.request'
import { getLang, getSelfSufficiencyPercentage, isRegion, OBLAST_KATO } from '../../utils/helpers.utils'
import { Text } from '../text.component'
import InvestProjectsFullScreen from './fullscreen/invest-projects-fullscreen.component'
import Screening from './tabs/screening.component'
import SER from './fullscreen/ser.component'
import DistrictIncomeFullScreen from './fullscreen/district-income-fullscreen.component'
import InfrastuctureFullScreen from './fullscreen/infrastructure-fullscreen.component'
import { getMMData } from '../../requests/kato.request'
import PupulationDynamicFullScreen from './fullscreen/population-dynamic-fullscreen.component'
import TaxComponent from './tax.component'
import { DeleteX, DeleteXWhite } from '../../assets/icons'

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string | null) => void;
  selectedMarker: IMarker | null;
  selectedRegion: any;
  toggleShowLeftMenu: () => void;
  showLeftMenu: boolean;
  setShowLeftMenu: (show: boolean) => void;
}

const RightMenu: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedMarker, selectedRegion, toggleShowLeftMenu, showLeftMenu, setShowLeftMenu }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { link, kato } = useParams()
  const [basicStats, setBasicStats] = useState<any>(null)
  const [additionalStats, setAdditionalStats] = useState<any>([])
  const [districtIncome, setDistrictIncome] = useState<IDistrictIncomeList | null>(null)
  const [investProjects, setInvestProjects] = useState<any>([])
  const [populationDemand, setPopulationDemand] = useState<IPopulationDemand[]>([])
  const [chartInfo, setChartInfo] = useState<any>({
    data: null,
    options: null
  })
  const [psdData, setPsdData] = useState<IPSDItem[]>([])
  const gridRef = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState<string | undefined>(undefined);
  const [fontCoef, setFontCoef] = useState<number>(1);
  const [productSelfSufficiency, setProductSelfSufficiency] = useState<IProductSufficiencyItem[]>([]);
  const [budgetData, setBudgetData] = useState<IBudgetItem>();
  const [populationDynamicStats, setPopulationDynamicStats] = useState<IPopulationDynamic[]>([]);
  const [roadData, setRoadData] = useState<IRoadShare>({} as IRoadShare);
  const [mmData, setMMData] = useState<any>(null);
  const [populationDetails, setPopulationDetails] = useState<IPopulationDetails>();
  const [taxStats, setTaxStats] = useState<ITaxStat[]>([]);

  const [fullScreen, setFullScreen] = useState<{
    isOpen: boolean;
    type: string | null;
  }>({
    isOpen: false,
    type: null,
  });

  const closeModal = () => {
    setIsOpen(false);
    setShowLeftMenu(true);
    // setSelectedLink(null);
    // navigate('/');
  };

  const getColor = (year: number, isTicks = false) => {
    if (year === currentYear) {
      return theme === Theme.LIGHT ? '#118F84' : '#ffc803';
    } if (year < currentYear) {
      return theme === Theme.LIGHT ? isTicks ? '#98A3A9' : "#92D050" : '#fff';
    } else {
      return theme === Theme.LIGHT ? isTicks ? '#606367' : "#226AC3" : 'rgba(255, 255, 255, 0.5)';
    }
  };

  const getChartInfo = (chartData: IPopulationDynamic[]) => {
    const years = chartData?.map((item: IPopulationDynamic) => item.year);
    const data: any = {
      labels: years,
      datasets: [
        {
          label: 'Население',
          data: chartData.map((item: IPopulationDynamic) => (item.value)),
          borderColor: years.map(year => getColor(year)),
          backgroundColor: years.map(year => getColor(year)),
          pointRadius: 4 * fontCoef,
          pointBackgroundColor: years.map(year => getColor(year)),
          borderDash: years.map(year => year < currentYear ? [] : [5, 5]),
          segment: {
            borderDash: (ctx: any) => +ctx.p0DataIndex >= 3 ? [3, 3] : [],
            borderColor: (ctx: any) => ctx.p0DataIndex < 3 ? '#92D050' : '#226AC3',
          },
          spanGaps: true
        },
      ],
    };

    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false,
            drawTicks: false,
          },
          position: 'top',
          ticks: {
            color: years.map(year => getColor(year, true)),
            align: 'center',
            baseLine: 'middle',
            font: {
              size: years.map(year => year === currentYear ? 14 * fontCoef : 12 * fontCoef),
              weight: years.map(year => year === currentYear ? 700 : 400),
            },
          },
          offset: true,
        },
        y: {
          grid: {
            color: '#c6cacc',
            beginAtZero: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
            maxTicksLimit: 4,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: years.map(year => getColor(year)),
          formatter: function (value: any, context: any) {
            const coef = context.chart.data.datasets[0].data[3] >= 1000 ? 1000 : 1
            return coef === 1 ? value : (value / coef).toFixed(1).toLocaleString();
          },
          font: years.map(year => ({
            size: year === currentYear ? 14 * fontCoef : 12 * fontCoef,
            weight: year === currentYear ? 700 : 400,
          })),
          textAlign: 'center',
          clamp: true,
          anchor: 'start',
          align: (context: any) => {
            const index = context.dataIndex;
            const datasets = context.chart.data.datasets[0].data;
            const average = datasets.reduce((acc: number, item: number) => acc + item, 0) / datasets.length;
            return datasets[index] <= average ? 'end' : 'start';
          },
        }
      },
      layout: {
        backgroundColor: '#F8F7EF',
      },
    };

    setChartInfo({ data, options });
  }

  const handleFullScreen = (obj: any) => {
    setFullScreen(obj);
  };

  useEffect(() => {
    if (selectedTab) {
      if (selectedTab !== 'snp') {
        // setIsOpen(true);
        // setSelectedLink(null);
        kato ? navigate(`/${kato}/${selectedTab}`) : navigate(`/${selectedTab}`)
      } else {
        !selectedLink && setSelectedLink(navLinks[0].name);
        kato ? navigate(`/${kato}/snp`) : navigate('/snp')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, setSelectedLink])

  useEffect(() => {
    if (selectedLink) {
      // setIsOpen(true);
    }
    if (kato) {
      if (!selectedTab) {
        setSelectedTab('snp')
      }
    }
  }, [selectedLink, kato])

  useEffect(() => {
    link && setSelectedTab(link);
  }, [link])

  useEffect(() => {
    if (kato || selectedMarker) {
      setIsOpen(true);
      const val = selectedMarker ? selectedMarker.kato : kato;
      if (val) {
        getMMData(val).then((res) => {
          setMMData(res);
        })

        getGeneralInfo(+val).then((res) => {
          if (res.basicStats?.every((stat: any) => stat.previousYearVal === null || stat.currentYearVal === null || stat.increaseVal === null)) {
            setBasicStats([])
          } else {
            const grouped = res.basicStats?.filter((item: any) => item.type !== 'ADDITIONAL_STAT').reduce((acc: any, stat: any) => {
              if (!acc[stat.type]) {
                acc[stat.type] = [];
              }
              acc[stat.type].push(stat);
              return acc;
            }, {});

            const additionalStats = res.basicStats?.filter((item: any) => item.type === 'ADDITIONAL_STAT');

            const groupedAdditionalStats = additionalStats?.reduce((acc: any, stat: any) => {
              if (!acc[stat.additionalInfo?.[`type${getLang()}`]]) {
                acc[stat.additionalInfo?.[`type${getLang()}`]] = [];
              }
              acc[stat.additionalInfo?.[`type${getLang()}`]].push(stat);
              return acc;
            }, {})
            setBasicStats(grouped);
            setAdditionalStats(groupedAdditionalStats);
          }
          const sortedInvestProjects = res.budgetInvestmentProjectStats
            .sort((a: any, b: any) => b.totalPrice - a.totalPrice)
            .map((item: any) => ({ ...item, realizationDate: item.realizationDate ? item.realizationDate.split('T')[0].split('-').reverse().join('.') : '' }));
          setPopulationDemand(res.populationDemandStats)
          setInvestProjects(sortedInvestProjects)
          setDistrictIncome(res.districtIncomeStats)
          setPopulationDynamicStats(res.populationIncreaseDynamicStats.sort((a, b) => a.year - b.year))
          getChartInfo(res.populationIncreaseDynamicStats.sort((a, b) => a.year - b.year))
          setPsdData(res.projectEstimateDocumentation)
          setProductSelfSufficiency(
            res.productSelfSufficiencyStats.map((item: IProductSufficiencyItem) => ({
              ...item,
              selfSufficiencyPercentage: +getSelfSufficiencyPercentage(item)
            })).sort((a, b) => b.selfSufficiencyPercentage - a.selfSufficiencyPercentage))
          setBudgetData(res.budgetStats)
          setRoadData(res.totalRoadShareStats)
          setPopulationDetails(res.populationDetailsStats)
          setTaxStats(res.taxStats)
        })
      }

      handleFullScreen({ setOpen: false, type: null });
    }
  }, [kato, selectedMarker, fontCoef])

  const updateMaxWidth = () => {
    if (gridRef.current) {
      setMaxWidth(((gridRef.current.clientWidth / 3)) + 'px');
      if (gridRef.current.clientWidth >= 1500 && gridRef.current.clientWidth < 2550) {
        setFontCoef(1.25);
      } else if (gridRef.current.clientWidth >= 2550) {
        setFontCoef(1.5);
      } else {
        setFontCoef(1);
      }
    }
  };

  useEffect(() => {
    updateMaxWidth();
  }, [gridRef, gridRef.current?.clientWidth, showLeftMenu])

  useEffect(() => {
    updateMaxWidth();

    const handleResize = () => {
      updateMaxWidth();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [gridRef]);

  useEffect(() => {
    getChartInfo(populationDynamicStats);

    // setFullScreen({
    //   isOpen: true,
    //   type: 'ROAD',
    // });
  }, [theme])

  return (
    <>
      {isOpen && kato && <RightMenuContainer isFull={!showLeftMenu} style={{ minHeight: fullScreen.isOpen ? 'calc(100vh - 1rem)' : 'min-content' }}>
        <Header showLeftMenu={showLeftMenu} toggleShowLeftMenu={toggleShowLeftMenu} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onClose={() => closeModal()} />
        <Filters>
          <Text fontWeight={500} color='var(--text-color)' fontSize="1.125rem">
            {
              isRegion(+kato)
                ? <>
                  {selectedRegion?.[`name${getLang()}`].toUpperCase().trim()}
                </>
                : <>
                  {selectedMarker?.nameRu.toUpperCase().trim()}
                </>
            }
            {fullScreen.isOpen &&
              <>
                {` - ${t(`new-fields.${fullScreen.type}`).toUpperCase()}`}
              </>
            }
          </Text>
          {
            fullScreen.isOpen &&
            <>
              {theme === Theme.LIGHT
                ? <DeleteX className='close-icon' onClick={() => handleFullScreen({ isOpen: false, type: null })} width={12} height={12} />
                : <DeleteXWhite className='close-icon' onClick={() => handleFullScreen({ isOpen: false, type: null })} width={12} height={12} />}
            </>

          }

        </Filters>
        {
          fullScreen.isOpen ? <>
            {fullScreen.type === 'FOOD' && productSelfSufficiency && productSelfSufficiency.length > 0 && <FoodContainer data={productSelfSufficiency} />}
            {psdData && fullScreen.type === 'PSD' && <PSDFullScreen kato={selectedRegion.kato} />}
            {fullScreen.type === 'ROAD' && roadData && <Road data={roadData} />}
            {fullScreen.type === 'InvestProjects' && <InvestProjectsFullScreen data={investProjects} />}
            {fullScreen.type === 'SER' && basicStats && kato && <SER kato={kato} DATA={basicStats} />}
            {fullScreen.type === 'DistrictIncome' && <DistrictIncomeFullScreen kato={kato} />}
            {fullScreen.type === 'INFRASTRUCTURE' && <InfrastuctureFullScreen />}
            {fullScreen.type === 'population-dynamic' && populationDetails && <PupulationDynamicFullScreen populationDetails={populationDetails} populationDynamic={populationDynamicStats} />}
          </>
            : <div className="grid" ref={gridRef}>
              {
                selectedTab === 'snp' ? <>
                  <BasicStatsContainer maxWidth={maxWidth}>
                    {basicStats && additionalStats && isRegion(+kato) && <BasicStats kato={+kato} filtered={['SER']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                    {basicStats && additionalStats && !isRegion(+kato) && <BasicStats filtered={['FOOD']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                    {investProjects && budgetData && isRegion(+kato) && <InvestProjects budgetData={budgetData} handleFullScreen={handleFullScreen} data={investProjects} />}
                    {districtIncome && isRegion(+kato) && +kato !== OBLAST_KATO && <DistrictIncome kato={selectedRegion?.kato} handleFullScreen={handleFullScreen} data={districtIncome} />}
                    {+kato === OBLAST_KATO && taxStats && <TaxComponent handleFullScreen={handleFullScreen} data={taxStats} />}
                  </BasicStatsContainer>

                  <BasicStatsContainer maxWidth={maxWidth}>
                    {psdData && isRegion(+kato) && <PSD handleFullScreen={handleFullScreen} data={psdData} />}
                    {basicStats && additionalStats && isRegion(+kato) && <BasicStats filtered={['FOOD']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                    {populationDemand && populationDemand.length > 0 && selectedRegion && <PopulationDemand kato={selectedRegion?.kato} data={populationDemand} />}

                    {basicStats && !isRegion(+kato) && <BasicStats filtered={['INFRASTRUCTURE']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                    {investProjects && budgetData && !isRegion(+kato) && <InvestProjects budgetData={budgetData} handleFullScreen={handleFullScreen} data={investProjects} />}

                  </BasicStatsContainer>

                  <BasicStatsContainer maxWidth={maxWidth}>
                    {
                      chartInfo.data && chartInfo.data?.datasets[0]?.data?.length
                      && chartInfo.data?.datasets[0]?.data?.some((item: number) => item !== null) && chartInfo.options
                      && <PopulationDynamic handleFullScreen={handleFullScreen} chartInfo={chartInfo} />
                    }
                    {basicStats && isRegion(+kato) && <BasicStats filtered={['INFRASTRUCTURE']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} />}
                    {basicStats && additionalStats && <BasicStats filtered={['ROAD', 'ADDITIONAL_TYPE']} handleFullScreen={handleFullScreen} basicStats={basicStats} additionalStats={additionalStats} totalRoadShareStats={roadData} />}

                    {isRegion(+kato) && <MediaMonitoring list={mmData} regionName={selectedRegion?.nameRu} />}
                  </BasicStatsContainer>
                </>
                  : <>
                    {basicStats?.FOOD && maxWidth && fontCoef && <Screening fontCoef={fontCoef} maxWidth={maxWidth} foodData={basicStats.FOOD} />}
                  </>
              }

            </div>
        }

      </RightMenuContainer >
      }
    </>
  )
}

export default RightMenu