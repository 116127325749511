import axios from "axios";
import { IMarker, IRegion, ISettlementsObject } from "../interfaces/snp.interface";

export const getRegionList = (): Promise<IRegion[]> => {
  return axios.get('/api/public/kato/list').then(res => res.data.list);
}

export const getMarkers = (katoCode: number): Promise<IMarker[]> => {
  return axios.get(`/api/public/kato/coordinate?kato=${katoCode}`).then(res => res.data.list);
}

export const getSettlementTree = (language: 'RU' | 'KZ' = 'RU'): Promise<ISettlementsObject> => {
  return axios.get(`/api/public/kato/tree?language=${language}`).then(res => res.data);
}

export const getSettlementTreeV2 = (language: 'RU' | 'KZ' = 'RU'): Promise<ISettlementsObject> => {
  return axios.get(`/api/public/kato/tree/v2?language=${language}`).then(res => res.data);
}

export const getStatistics = (): Promise<any> => {
  return axios.get('/api/public/kato/statistics').then(res => res.data.data);
}

export const getLink = (kato: any) => {
  return axios.get(`/api/public/link?kato=${kato}`).then(res => res.data.data)
}

export const getMMData = (kato: any) => {
  return axios.get(`/api/public/media-monitoring?kato=${kato}`).then(res => res.data.list)
};

export const searchRoad = (query: string) => {
  return axios.get(`/api/public/road/search?searchTerm=${query}`).then(res => res.data)
}

export const downloadRoadToExcel = (query: string) => {
  return axios
    .get(`/api/public/road/download?searchTerm=${query}`, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}