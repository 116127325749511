import React, { useContext } from 'react'
import { ThemeSwitchContainer } from './index.styles'
import ThemeContext, { Theme } from '../../contexts/theme.context';

const ThemeSwitch = () => {
  const { theme, handleChangeTheme } = useContext(ThemeContext);

  const onChange = () => {
    handleChangeTheme(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
  }

  return (
    <ThemeSwitchContainer>
      <div>
        <input type="checkbox" className="checkbox" id="toggleBtn" onChange={onChange} checked={theme === Theme.LIGHT} />
        <label className="switch" htmlFor="toggleBtn">
          <div className='icon'>🌞</div>
          <div className='icon'>🌚</div>
          <div className="ball"></div>
        </label>
      </div>
    </ThemeSwitchContainer>
  )
}

export default ThemeSwitch