/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { AddField, GoUp, Save } from '../../assets/icons';
import { checkVal, getBudgetTotals, getDevelopmentPercentage, getLang, handleGoUp, investProjectFields, isRegion, months, quarters, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { IBudgetItem, IInvestmentProject } from '../../interfaces/snp.interface';
import { FormBlockButton } from '../../components/insfrastructureProjects/infrastructure.styles';
import { updateFormPartial } from '../../requests/supervisor.request';

const BudgetPage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<any[]>([]);
  const [snps, setSnps] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [budgetStats, setBudgetStats] = useState<IBudgetItem>({
    budgetProcessing: 0,
    curBudgetAnnualPlan: 0,
    curBudgetMonthlyFact: 0,
    curBudgetMonthlyPlan: 0,
    devBudgetAnnualPlan: 0,
    devBudgetMonthlyFact: 0,
    devBudgetMonthlyPlan: 0,
    totalBudgetAnnualPlan: 0,
    totalBudgetMonthlyFact: 0,
    totalBudgetMonthlyPlan: 0,
    curBudgetMonthlyProcessing: 0,
    curBudgetAnnualProcessing: 0,
    devBudgetMonthlyProcessing: 0,
    devBudgetAnnualProcessing: 0,
    totalBudgetMonthlyProcessing: 0,
    totalBudgetAnnualProcessing: 0,
    totalProjectCount: 0
  });
  const [budgetInvestmentProjectStats, setBudgetInvestmentProjectStats] = useState<IInvestmentProject[]>([]);
  const [isAddingNewInvestProject, setIsAddingNewInvestProject] = useState(false);
  const [newInvestProject, setNewInvestProject] = useState<IInvestmentProject>({
    nameRu: '',
    nameKz: '',
    totalPrice: 0,
    currentPrice: 0,
    developmentShare: 0,
    city: '',
    realizationDate: '',
    quarter: ''
  })


  const validateForm = (data: any) => {
    setErrors({});
    let res = true;
    // SECTION NINE
    const budgetData: any = budgetStats;
    const keys = ['curBudgetMonthlyPlan', 'curBudgetMonthlyFact', 'curBudgetAnnualPlan', 'devBudgetMonthlyPlan', 'devBudgetMonthlyFact', 'devBudgetAnnualPlan']
    if (keys.some(key => checkVal(budgetData[key]) && +budgetData[key] !== 0)) {
      for (const key of keys) {
        if (!checkVal(budgetData[key]) || +budgetData[key] === 0) {
          setErrors((prev: any) => ({ ...prev, [`budgetStats.${key}`]: true }))
          toast.error(t(`errors.streetName`, language));
          return false;
        }
      }
    }

    // SECTION TEN
    const budgetInvestmentData: any = budgetInvestmentProjectStats;
    let index = 0;
    for (const item of budgetInvestmentData) {
      const keys = Object.keys(item).filter(key => !['realizationDate', 'quarter'].includes(key));
      for (const key of keys) {
        if (!checkVal(item[key])) {
          const errKey = ['nameKz', 'nameRu'].includes(key) ? 'name' : key;
          setErrors((prev: any) => ({ ...prev, [`budgetInvestmentProjectStats[${index}].${errKey}`]: true }))
          toast.error(t(`errors.streetName`, language));
          return;
        }
      }
      index++;
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      budgetStats,
      budgetInvestmentProjectStats
    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    setSnps(regions.find((item) => +item.value === +e.target.value)?.children || []);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/budget`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }
  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      setBudgetStats(res.budgetStats);
      setBudgetInvestmentProjectStats(res.budgetInvestmentProjectStats.map((item: any) => ({
        ...item,
        realizationDate: item.realizationDate ? item.realizationDate.split('T')[0] : ''
      })));
      setIsLoading(false);
    });

  }, [region, snp, kato])

  const handleAddInvestProject = () => {

    const keys: any = Object.keys(newInvestProject).filter(key => key !== 'developmentShare');
    for (const key of keys) {
      if (!checkVal(newInvestProject[key as keyof IInvestmentProject])) {
        toast.error(t(`errors.streetName`, language));
        setErrors((prev: any) => ({ ...prev, [`newInvestProject.${key}`]: true }))
        return;
      }
    }

    const newVal = {
      ...newInvestProject,
      developmentShare: (newInvestProject.currentPrice / newInvestProject.totalPrice) * 100,
    }

    kato && updateFormPartial(kato, { budgetInvestmentProjectStats: [...budgetInvestmentProjectStats, newVal] })

    setBudgetInvestmentProjectStats([
      ...budgetInvestmentProjectStats,
      newVal
    ])

    setIsAddingNewInvestProject(false);
    setNewInvestProject({
      nameRu: '',
      nameKz: '',
      totalPrice: 0,
      currentPrice: 0,
      developmentShare: 0,
      city: '',
      realizationDate: '',
      quarter: ''
    })
  }

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        budgetStats && <FormBlock id="budget" type='white'>
          <div className="title bold">{t('budget.title')}</div>
          <FormBlock type='white'>
            <div className="title bold">{t('budget.currentProjects')}</div>
            {
              ['curBudgetMonthlyPlan', 'curBudgetMonthlyFact', 'curBudgetAnnualPlan'].map((key: string, index: number) => (
                <FormGroup key={key}>
                  <label htmlFor={`budgetStats.${key}`}>
                    {t(`budget.${key}`, lang)} {!key.includes('Annual') && `1 ${months[new Date().getMonth()]}`}
                  </label>
                  <Field
                    name={`budgetStats.${key}`}
                    type="number"
                    as="input"
                    className={`${errors[`budgetStats.${key}`] ? 'error' : ''}`}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      setFieldValue(`budgetStats.${key}`, val);
                      setBudgetStats({
                        ...budgetStats,
                        [key]: val
                      })
                    }}
                    value={budgetStats[key as keyof IBudgetItem] || ''}
                  />
                </FormGroup>
              ))
            }
            {
              ['curBudgetMonthlyProcessing', 'curBudgetAnnualProcessing'].map((key: string, index: number) => (
                <FormGroup key={key}>
                  <label htmlFor={`budgetStats.${key}`}>
                    {t(`budget.${key}`, lang)} {!key.includes('Annual') && `1 ${months[new Date().getMonth()]}`}
                  </label>
                  <Field
                    name={`budgetStats.${key}`}
                    type="number"
                    as="input"
                    disabled
                    value={getBudgetTotals(budgetStats, key as keyof IBudgetItem) || ''}
                  />
                </FormGroup>
              ))
            }
          </FormBlock>

          <FormBlock type='white'>
            <div className="title bold">{t('budget.devProjects')}</div>
            {
              ['devBudgetMonthlyPlan', 'devBudgetMonthlyFact', 'devBudgetAnnualPlan'].map((key: string, index: number) => (
                <FormGroup key={key}>
                  <label htmlFor={`budgetStats.${key}`}>
                    {t(`budget.${key}`, lang)} {!key.includes('Annual') && `1 ${months[new Date().getMonth()]}`}
                  </label>
                  <Field
                    name={`budgetStats.${key}`}
                    type="number"
                    as="input"
                    className={`${errors[`budgetStats.${key}`] ? 'error' : ''}`}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      setFieldValue(`budgetStats.${key}`, val);
                      setBudgetStats({
                        ...budgetStats,
                        [key]: val
                      })
                    }}
                    value={budgetStats[key as keyof IBudgetItem] || ''}
                  />
                </FormGroup>
              ))
            }
            {
              ['devBudgetMonthlyProcessing', 'devBudgetAnnualProcessing'].map((key: string, index: number) => (
                <FormGroup key={key}>
                  <label htmlFor={`budgetStats.${key}`}>
                    {t(`budget.${key}`, lang)} {!key.includes('Annual') && `1 ${months[new Date().getMonth()]}`}
                  </label>
                  <Field
                    name={`budgetStats.${key}`}
                    type="number"
                    as="input"
                    disabled
                    value={getBudgetTotals(budgetStats, key as keyof IBudgetItem) || ''}
                  />
                </FormGroup>
              ))
            }
          </FormBlock>
          <FormBlock type='white'>
            <div className="title bold">{t('budget.total')}</div>
            {
              ['totalBudgetAnnualPlan', 'totalBudgetMonthlyPlan', 'totalBudgetMonthlyFact', 'totalBudgetMonthlyProcessing', 'totalBudgetAnnualProcessing'].map((key: string, index: number) => (
                <FormGroup key={key}>
                  <label htmlFor={`budgetStats.${key}`}>
                    {t(`budget.${key}`, lang)} {!key.includes('Annual') && `1 ${months[new Date().getMonth()]}`}
                  </label>
                  <Field
                    name={`budgetStats.${key}`}
                    type="number"
                    as="input"
                    disabled
                    value={getBudgetTotals(budgetStats, key as keyof IBudgetItem) || ''}
                  />
                </FormGroup>
              ))
            }
          </FormBlock>
          <FormGroup>
            <label htmlFor={`budgetStats.totalProjectCount`}>
              {t(`budget.totalProjectCount`, lang)}
            </label>
            <Field
              name={`budgetStats.totalProjectCount`}
              type="number"
              as="input"
              className={`${errors[`budgetStats.totalProjectCount`] ? 'error' : ''}`}
              onChange={(e: any) => {
                const val = e.target.value;
                setFieldValue(`budgetStats.totalProjectCount`, val);
                setBudgetStats({
                  ...budgetStats,
                  totalProjectCount: val
                })
              }}
            />
          </FormGroup>
        </FormBlock>
      }

      {
        budgetInvestmentProjectStats && budgetInvestmentProjectStats.length !== 0 &&
        <>
          <div className="title bold" id="BIP">{t('budgetInvestmentProjectStats.title')}</div>
          {budgetInvestmentProjectStats.map((item: IInvestmentProject, index: number) => (
            <FormBlock type='white' key={index}>
              <FormGroup >
                <label
                  htmlFor={`budgetInvestmentProjectStats[${index}].name${lang}`}
                  className="required"
                >{t(`budgetInvestmentProjectStats.name`)}
                </label>
                <Field
                  name={`budgetInvestmentProjectStats[${index}].name${lang}`}
                  className={`${errors[`budgetInvestmentProjectStats[${index}].name`] ? 'error' : ''}`}
                  value={item[`name${lang}`]}
                  onChange={(e: any) => {
                    setFieldValue(`budgetInvestmentProjectStats[${index}].name${lang}`, e.target.value)
                    setBudgetInvestmentProjectStats(budgetInvestmentProjectStats.map((item: IInvestmentProject, i: number) => i === index ? {
                      ...item,
                      [`name${lang}`]: e.target.value
                    } : item))
                  }}
                />
              </FormGroup>
              {
                investProjectFields.map(({ name, type }) => (
                  <FormGroup>
                    <label
                      htmlFor={`budgetInvestmentProjectStats[${index}].${name}`}
                      className="required"
                    > {name === 'realizationDate' ? t(`budgetInvestmentProjectStats.year`) : t(`budgetInvestmentProjectStats.${name}`)}</label>

                    {name === 'developmentShare'
                      ?
                      <Field
                        name={`budgetInvestmentProjectStats[${index}].${name}`}
                        className={`${errors[`budgetInvestmentProjectStats[${index}].${name}`] ? 'error' : ''}`}
                        type={type}
                        disabled
                        value={((item.currentPrice / item.totalPrice) * 100).toFixed(1) || ''}
                      />
                      :
                      name === 'quarter' ?
                        <Field
                          name={`budgetInvestmentProjectStats[${index}].${name}`}
                          className={`${errors[`budgetInvestmentProjectStats[${index}].${name}`] ? 'error' : ''}`}
                          type={type}
                          as="select"
                          value={item[name as keyof IInvestmentProject]}
                          onChange={(e: any) => {
                            setFieldValue(`budgetInvestmentProjectStats[${index}].${name}`, e.target.value);
                            setBudgetInvestmentProjectStats(budgetInvestmentProjectStats.map((item: IInvestmentProject, i: number) => i === index ? {
                              ...item,
                              [name]: e.target.value
                            } : item))
                          }}
                          defaultValue={item[name as keyof IInvestmentProject] || ''}
                        >
                          <option value="" hidden></option>
                          {quarters.map((item, index) => <option key={index} value={item}>{item}</option>)}
                        </Field>
                        : <Field
                          name={`budgetInvestmentProjectStats[${index}].${name}`}
                          className={`${errors[`budgetInvestmentProjectStats[${index}].${name}`] ? 'error' : ''}`}
                          type={type}
                          as="input"
                          value={
                            (item[name as keyof IInvestmentProject] || +item[name as keyof IInvestmentProject] === 0)
                              ? item[name as keyof IInvestmentProject]
                              : ''
                          }
                          step="0.000001"
                          onChange={(e: any) => {
                            if (type === 'number') {
                              const newValue = +e.target.value;
                              if (e.target.value === '') {
                                setFieldValue(`budgetInvestmentProjectStats[${index}].${name}`, e.target.value);
                                setBudgetInvestmentProjectStats(budgetInvestmentProjectStats.map((item: IInvestmentProject, i: number) => i === index ? {
                                  ...item,
                                  [name]: e.target.value
                                } : item))
                              } else {
                                let updatedInvestProject = [...budgetInvestmentProjectStats];
                                if (name === 'currentPrice') {
                                  const val = Math.min(newValue, +item.totalPrice);

                                  updatedInvestProject = updatedInvestProject.map((item: IInvestmentProject, i: number) => i === index ? {
                                    ...item,
                                    currentPrice: +val,
                                  } : item);
                                  setFieldValue(`budgetInvestmentProjectStats[${index}].${name}`, val.toString());
                                } else if (name === 'totalPrice') {
                                  const val = newValue;
                                  updatedInvestProject = updatedInvestProject.map((item: IInvestmentProject, i: number) => i === index ? {
                                    ...item,
                                    totalPrice: +val,
                                    currentPrice: +Math.min(+item.currentPrice, +val),
                                  } : item);
                                  setFieldValue(`budgetInvestmentProjectStats[${index}].${name}`, val.toString());
                                  setFieldValue(`budgetInvestmentProjectStats[${index}].currentPrice`, updatedInvestProject[index].currentPrice.toString());
                                } else {
                                  updatedInvestProject = updatedInvestProject.map((item: IInvestmentProject, i: number) => i === index ? {
                                    ...item,
                                    [name]: newValue,
                                  } : item);
                                  setFieldValue(`budgetInvestmentProjectStats[${index}].${name}`, newValue.toString());
                                }
                                setBudgetInvestmentProjectStats(updatedInvestProject);
                              }
                            } else {
                              setFieldValue(`budgetInvestmentProjectStats[${index}].${name}`, e.target.value);
                              setBudgetInvestmentProjectStats(budgetInvestmentProjectStats.map((item: IInvestmentProject, i: number) => i === index ? {
                                ...item,
                                [name]: e.target.value
                              } : item))
                            }
                          }}
                        />
                    }
                  </FormGroup>
                ))
              }
            </FormBlock>

          ))}

          <FormGroup>
            <label htmlFor={`budgetInvestmentProjectStats.developmentPercentage`}>
              {t(`budgetInvestmentProjectStats.developmentPercentage`, lang)}
            </label>
            <Field
              name={`budgetInvestmentProjectStats.developmentPercentage`}
              type="number"
              as="input"
              disabled
              value={getDevelopmentPercentage(budgetInvestmentProjectStats)}
            />
          </FormGroup>
        </>
      }

      {
        isAddingNewInvestProject && <FormBlock type='white'>
          <React.Fragment >
            <FormGroup >
              <label
                htmlFor={`newInvestProject.name${lang}`}
                className="required"
              >{t(`budgetInvestmentProjectStats.name`)}
              </label>
              <Field
                name={`newInvestProject.name${lang}`}
                className={`${errors[`newInvestProject.name${lang}`] ? 'error' : ''}`}
                onChange={(e: any) => {
                  setFieldValue(`newInvestProject.name${lang}`, e.target.value)
                  setNewInvestProject({
                    ...newInvestProject,
                    [`name${lang}`]: e.target.value
                  })
                }}
                value={newInvestProject[`name${lang}`] || ''}
              />
            </FormGroup>
            {
              investProjectFields.map((field, i) => (
                <FormGroup>
                  <label
                    htmlFor={`newInvestProject.${field.name}`}
                    className="required"
                  >{t(`budgetInvestmentProjectStats.${field.name}`)}</label>
                  {field.name === 'developmentShare'
                    ?
                    <Field
                      name={`newInvestProject.${field.name}`}
                      className={`${errors[`newInvestProject.name${lang}`] ? 'error' : ''}`}
                      type='number'
                      disabled
                      value={((newInvestProject.currentPrice / newInvestProject.totalPrice) * 100).toFixed(1) || ''}
                      onChange={(e: any) => {
                        setFieldValue(`newInvestProject.${field.name}`, ((newInvestProject.currentPrice / newInvestProject.totalPrice) * 100).toFixed(1))
                        setNewInvestProject({
                          ...newInvestProject,
                          [field.name]: e.target.value
                        })
                      }}
                    />
                    :
                    <Field
                      name={`newInvestProject.${field.name}`}
                      className={`${errors[`newInvestProject.${field.name}`] ? 'error' : ''}`}
                      type='number'
                      onChange={(e: any) => {
                        const newValue = +e.target.value;
                        let updatedNewInvestProject = { ...newInvestProject };

                        if (field.name === 'currentPrice') {
                          const val = Math.min(newValue, +newInvestProject.totalPrice);
                          updatedNewInvestProject = {
                            ...updatedNewInvestProject,
                            currentPrice: +val,
                          };
                          setFieldValue(`newInvestProject.${field.name}`, val.toString());
                        } else if (field.name === 'totalPrice') {
                          const val = newValue;
                          updatedNewInvestProject = {
                            ...updatedNewInvestProject,
                            totalPrice: +val,
                            currentPrice: +Math.min(+updatedNewInvestProject.currentPrice, +val),
                          };
                          setFieldValue(`newInvestProject.${field.name}`, val.toString());
                          setFieldValue('newInvestProject.currentPrice', updatedNewInvestProject.currentPrice.toString());
                        }

                        setNewInvestProject(updatedNewInvestProject);
                      }}
                      value={newInvestProject[field.name as keyof IInvestmentProject] || ''}
                    />
                  }
                </FormGroup>
              ))
            }
          </React.Fragment>
        </FormBlock>
      }

      {
        isAddingNewInvestProject
          ? <>
            <FormBlockButton onClick={handleAddInvestProject}>{t('save', lang)}</FormBlockButton>
            <FormBlockButton onClick={() => {
              setNewInvestProject({
                nameRu: '',
                nameKz: '',
                currentPrice: 0,
                totalPrice: 0,
                developmentShare: 0,
                city: '',
                realizationDate: '',
                quarter: ''
              })
              setIsAddingNewInvestProject(false)
            }}>{t('cancel', lang)}</FormBlockButton>
          </>
          : <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAddingNewInvestProject(true)}>
            <AddField /> {t('form.add_field', lang)}
          </FormBlockButton>
      }
    </div>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setRegions, setRegion, setSnps, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default BudgetPage