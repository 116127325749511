import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { BasicStatsItem, LegendGrid } from './index.styles'
import { Text } from '../text.component'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { IPSDItem } from '../../interfaces/snp.interface';
import { Expand, ExpandWhite } from '../../assets/icons';
import ThemeContext, { Theme } from '../../contexts/theme.context';
import { getLastWeekdayDate } from '../../utils/helpers.utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const firstKeys = ['Здравоохранение', 'ИКИ', 'Дороги', 'Образование', 'Связь', 'Культура и спорт']

interface IPSD {
  data: IPSDItem[];
  handleFullScreen: (obj: any) => void;
}

const PSD: FC<IPSD> = ({ data, handleFullScreen }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const chartRef = useRef<any>(null)
  const [legendList, setLegendList] = useState<any[]>([])
  const [chartData, setChartData] = useState<any>(null);
  const [chartOptions, setChartOptions] = useState<any>(null);
  const [maxSum, setMaxSum] = useState<number>(100);
  const [totalSum, setTotalSum] = useState<number>(0);

  const [visibleDatasets, setVisibleDatasets] = useState<any>({
    0: true,
    1: true,
  });


  const handleLabelClick = (e: any, item: any) => {
    if (chartRef.current) {
      const parent = e.target.parentNode
      parent.classList.toggle('selected')
      const isVisible = chartRef.current.isDatasetVisible(item.datasetIndex)
      chartRef.current.setDatasetVisibility(item.datasetIndex, !isVisible)
      chartRef.current.update()
      setVisibleDatasets({
        ...visibleDatasets,
        [item.datasetIndex]: !isVisible,
      });

    }
  }

  const sumInvestors = (data: IPSDItem) => {
    return data.governmentInvestorsCount + data.quasiGovernmentInvestorsCount;
  }

  useEffect(() => {
    const chart = chartRef.current
    if (chart) {
      const list = chart.legend?.legendItems
      setLegendList(list)
    }
  }, [chartData]);

  useEffect(() => {
    const inFirstKeys: any = [];
    const notInFirstKeys: any = [];

    data.filter(item => +sumInvestors(item) > 0).forEach(item => {
      if (firstKeys.includes(item.category)) {
        inFirstKeys.push(item);
      } else {
        notInFirstKeys.push(item);
      }
    });

    inFirstKeys.sort((a: any, b: any) => b.totalCount - a.totalCount);
    notInFirstKeys.sort((a: any, b: any) => b.totalCount - a.totalCount);

    const filteredFirst = inFirstKeys.filter((item: any) => item.governmentInvestorsCount > 0 || item.quasiGovernmentInvestorsCount > 0);

    const sortedAndFiltered = [...filteredFirst, ...notInFirstKeys].slice(0, 6).sort((a: any, b: any) => +sumInvestors(b) - +sumInvestors(a));

    const datasets = [
      {
        label: t('PSD.governmentInvestors'),
        data: sortedAndFiltered.map((item) => item.governmentInvestorsCount),
        backgroundColor: '#92D050',
        barThickness: 7,
        borderRadius: 10,
      },
      {
        label: t('PSD.quasiGovernmentInvestors'),
        data: sortedAndFiltered.map((item) => item.quasiGovernmentInvestorsCount),
        backgroundColor: '#226AC3',
        barThickness: 7,
        borderRadius: 10,
      },
    ]

    const sums = sortedAndFiltered.map((_, index) => {
      return datasets.reduce((sum, dataset) => sum + dataset.data[index], 0);
    });

    const maxSumVal = Math.max(...sums);
    sums.length > 0 && maxSumVal !== 0 && setMaxSum(maxSumVal);

    const processedLabels = sortedAndFiltered.map((item) => item.category.split(' ')).map((item: any) => item.includes('Культура') ? ['Культура', 'и спорт'] : item);

    setTotalSum(sums.reduce((sum, item) => sum + item, 0));

    setChartData({
      labels: processedLabels,
      datasets,
    });
  }, [data])


  const handleResize = () => {
    const fontCoef = window.innerWidth >= 1500 && window.innerWidth < 2550 ? 1.25 : window.innerWidth >= 2550 ? 1.5 : 1;

    setChartOptions({
      indexAxis: 'y',
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'rect',
          },
          display: false,
        },
        datalabels: {
          display: function (context: any) {
            const datasetIndex = context.datasetIndex;
            const visibleDatasetsArray = Object.keys(visibleDatasets)
              .filter((key) => visibleDatasets[key] === true)
              .map(Number);
            const lastVisibleDatasetIndex = Math.max(...visibleDatasetsArray);
            return datasetIndex === lastVisibleDatasetIndex;
          },
          anchor: 'end',
          align: 'end',
          formatter: function (value: any, context: any) {
            const visibleDatasetsArray = Object.keys(visibleDatasets)
              .filter((key) => visibleDatasets[key] === true)
              .map(Number);
            if (visibleDatasetsArray.length > 0) {
              const total = visibleDatasetsArray.reduce((sum, datasetIndex) => {
                return sum + context.chart.data.datasets[datasetIndex].data[context.dataIndex];
              }, 0);
              return total;
            }
            return '';
          },
          color: theme === Theme.LIGHT ? '#118F84' : '#ffc803', // Optionally set the color of the labels,
          font: {
            size: 16,
            weight: 'bold'
          }
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          display: false,
          grid: {
            display: false
          },
          stacked: true,
          min: 0,
          max: maxSum * 1.25,
        },
        y: {
          stacked: true,
          grid: {
            display: false
          },
          ticks: {
            color: theme === Theme.LIGHT ? '#202224' : '#fff',
            font: {
              size: 8 * fontCoef,
            }
          }
        }

      },
      responsive: true,
      maintainAspectRatio: false
    });
  }

  useEffect(() => {

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [theme, data, maxSum, visibleDatasets])


  return (
    maxSum !== Infinity && chartData && chartOptions && <BasicStatsItem style={{ maxHeight: '13.75rem' }} id="psd">
      <div className="title">
        <div className="left">
          <Text color='var(--text-color)' fontWeight={700} fontSize='0.75rem'>{t(`PSD.title`)}</Text>
          <Text color='var(--gray)' fontWeight={400} fontSize='0.75rem'>на {getLastWeekdayDate()}</Text>
        </div>
        <div className="right">
          <span className='unit-container' >
            {
              theme === Theme.LIGHT
                ? <Expand onClick={() => handleFullScreen({ isOpen: true, type: 'PSD' })} width={12} height={12} />
                : <ExpandWhite onClick={() => handleFullScreen({ isOpen: true, type: 'PSD' })} width={12} height={12} />
            }
          </span>
        </div>

      </div>
      <div className="body" style={{ paddingRight: 0 }}>
        <div className="df">
          <div className='chart-container' >
            <Bar data={chartData} options={chartOptions} ref={chartRef} />
          </div>
        </div>
        <LegendGrid
          columnNumber={3}
          style={{ borderTop: "1px solid #DBDCE0" }}
        >
          {legendList?.map((item: any) => (
            <div key={item.text} onClick={e => handleLabelClick(e, item)} >
              <div
                style={{ backgroundColor: item.fillStyle }}
                className='circle'
              ></div>
              <Text color='var(--text-color)' fontSize='0.6rem' fontWeight={400} className='legend-item'>
                {item.text}
              </Text>
            </div>
          ))}

          <div>
            <div
              style={{ backgroundColor: 'red' }}
              className='circle'
            ></div>
            <Text color='var(--text-color)' fontSize='0.6rem' fontWeight={400} className='legend-item'>
              Всего: {totalSum}
            </Text>
          </div>
        </LegendGrid>
      </div>

    </BasicStatsItem >
  )
}

export default PSD