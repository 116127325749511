import { createContext, FC, useEffect, useState } from "react";


export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

interface ThemeContextType {
  theme: string;
  handleChangeTheme: (theme: Theme) => void;
}

interface IProps {
  children: React.ReactNode;
}

const ThemeContext = createContext<ThemeContextType>({
  theme: Theme.LIGHT,
  handleChangeTheme: () => {},
});

const ThemeProvider: FC<IProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(Theme.LIGHT);

  const handleChangeTheme = (theme: Theme) => {
    setTheme(theme);
    localStorage.setItem('theme', theme);
  }

  useEffect(() => {
    const localTheme = localStorage.getItem('theme');
    if (localTheme) {
      setTheme(localTheme as Theme);
    } else {
      setTheme(Theme.LIGHT);
      localStorage.setItem('theme', Theme.LIGHT);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, handleChangeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider };
export default ThemeContext;
