import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { IBudgetItem, IInvestmentProject } from '../../interfaces/snp.interface';
import { getBudgetTotals, getLang } from '../../utils/helpers.utils';
import { Expand, ExpandWhite } from '../../assets/icons';
import ThemeContext, { Theme } from '../../contexts/theme.context';


const months = [
  'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];
interface IProps {
  data: IInvestmentProject[],
  handleFullScreen: (obj: any) => void;
  budgetData: IBudgetItem;
}

const InvestProjects: FC<IProps> = ({ data, handleFullScreen, budgetData }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [budgetTotals, setBudgetTotals] = useState<IBudgetItem | null>(null);

  useEffect(() => {
    if (budgetData) {
      setBudgetTotals({
        ...budgetData,
        totalBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'totalBudgetMonthlyProcessing') || 0,
        curBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'curBudgetMonthlyProcessing') || 0,
        devBudgetMonthlyProcessing: +getBudgetTotals(budgetData, 'devBudgetMonthlyProcessing') || 0,
        curBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'curBudgetAnnualProcessing') || 0,
        totalBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'totalBudgetAnnualProcessing') || 0,
        devBudgetAnnualProcessing: +getBudgetTotals(budgetData, 'devBudgetAnnualProcessing') || 0,
        totalBudgetMonthlyPlan: +getBudgetTotals(budgetData, 'totalBudgetMonthlyPlan') || 0,
        totalBudgetMonthlyFact: +getBudgetTotals(budgetData, 'totalBudgetMonthlyFact') || 0,
        totalBudgetAnnualPlan: +getBudgetTotals(budgetData, 'totalBudgetAnnualPlan') || 0
      })
    }
  }, [budgetData])

  return (
    budgetTotals && <BasicStatsItem id="invest-projects">
      <div className="title">
        <div className="left">
          <Text color='var(--text-color)' fontWeight={700} fontSize='0.75rem'>{t(`budgetInvestmentProjectStats.osvoenie`)}, млн тенге</Text>
          {/* <Text color='var(--gray)' fontWeight={400} fontSize='0.75rem'>по итогам 2023 года</Text> */}
        </div>

        <div className="right" style={{ display: 'flex', alignItems: 'center' }}>
          <span className='unit-container'>
            {/* <Text fontSize='0.75rem' color='var(--primary-color)' fontWeight={700} lineHeight='1.025625rem'>{getDevelopmentPercentage(data)}% освоения</Text> */}
            {
              theme === Theme.LIGHT
                ? <Expand onClick={() => handleFullScreen({ isOpen: true, type: 'InvestProjects' })} width={12} height={12} />
                : <ExpandWhite onClick={() => handleFullScreen({ isOpen: true, type: 'InvestProjects' })} width={12} height={12} />
            }
          </span>
        </div>

      </div>
      <div className="body">
        <div className="budget-grid">
          <div className='budget-grid__header'></div>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>План </Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>Факт </Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>Освоение </Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>План</Text>
          <Text className='budget-grid__header' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>Освоение</Text>

          <div className='budget-grid__header bordered'></div>
          <Text className='budget-grid__header span-3 bordered' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>на 1 {months[new Date().getMonth()]} </Text>
          <Text className='budget-grid__header span-2 bordered' fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem'>на 2024 </Text>
          <div className='bordered'></div>

          <Text className='budget-grid__item' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >Всего</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.totalBudgetMonthlyPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.totalBudgetMonthlyFact}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.totalBudgetMonthlyProcessing}%</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.totalBudgetAnnualPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.totalBudgetAnnualProcessing}%</Text>

          <Text className='budget-grid__item' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >Текущий <br /> бюджет</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.curBudgetMonthlyPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.curBudgetMonthlyFact}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.curBudgetMonthlyProcessing}%</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.curBudgetAnnualPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.curBudgetAnnualProcessing}%</Text>

          <Text className='budget-grid__item' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >Бюджет  <br /> развития</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.devBudgetMonthlyPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.devBudgetMonthlyFact}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.devBudgetMonthlyProcessing}%</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={400} >{budgetTotals.devBudgetAnnualPlan}</Text>
          <Text className='budget-grid__item' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.devBudgetAnnualProcessing}%</Text>
        </div>

        <div className="budget-grid__total">
          <Text lineHeight='0.75rem' fontSize='0.75rem' color='var(--text-color)' fontWeight={700} >Всего проектов</Text>
          <Text lineHeight='1rem' fontSize='1rem' color='var(--primary-color)' fontWeight={700} >{budgetTotals.totalProjectCount} ед</Text>
        </div>
        {data && data.length !== 0 && <>
          <div className="headers" style={{ paddingTop: 5 }}>
            <div style={{ width: "3rem", whiteSpace: 'nowrap' }}>
              <Text style={{ width: "3rem", whiteSpace: 'nowrap' }} fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem' fontWeight={400}>Выделено </Text>
              <Text style={{ minWidth: "3rem", paddingTop: "0.3125rem" }} className='currentYearVal tar' fontSize='0.625rem' color='var(--gray)' lineHeight='0.625rem' fontWeight={400}>
                млн тг
              </Text>
            </div>
            <Text style={{ width: "2.9rem" }} fontSize='0.625rem' color='var(--gray)' lineHeight='0.5625rem' fontWeight={400}>Освоено </Text>
          </div>
          {data.slice(0, 4).map((item: IInvestmentProject, index) => (
            <div
              className='body__item INVESTMENT'
              style={
                {
                  paddingTop: index === 0 ? '5px' : '10px',
                  borderBottom: index === 3 ? '0' : '1px solid var(--borderGray)',
                }}
              key={index}
            >
              <Text className='subType' fontSize='0.625rem' color='var(--text-color)' lineHeight='1' fontWeight={400}>
                {item[`name${getLang()}` as 'nameKz' | 'nameRu']}
              </Text>
              <Text style={{ minWidth: "3rem" }} className='currentYearVal tar' fontSize='1rem' color='var(--primary-color)' lineHeight='1' fontWeight={700}>
                {item.totalPrice}
              </Text>
              <Text style={{ minWidth: "2.9rem" }} className='currentYearVal tar' fontSize='1rem' color='var(--primary-color)' lineHeight='1' fontWeight={700}>{item.developmentShare}%</Text>
            </div>
          ))}
        </>}
      </div>
    </BasicStatsItem>
  )
}

export default InvestProjects