
import i18n from "../i18n";
import { IBudgetItem, IDistrictIncomeList, IInvestmentProject, IPopulationDemand, IPopulationDetails, IProductSufficiencyItem, ITaxStat } from "../interfaces/snp.interface";
import { getRegionList, getSettlementTree } from "../requests/kato.request";


const formatDate = (date: string) => {
  if (date) {
    return new Date(date).toLocaleDateString('ru').replaceAll('.', '-') || '';
  } else {
    return '';
  }
}

const t = (key: string, lng: string = 'ru', options?: any) => i18n.getResource(lng.toLowerCase(), 'translation', key, options) || key;

const getLang = () => {
  const { language } = i18n;
  return language === 'kz' ? 'Kz' : 'Ru';
}

const capitalize = (str: string) => str?.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

const keepCapitalized = (str: string) => str?.split(' ').map(str => str.charAt(0) + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

function lowerAndTrim(inputString: string) {
  if (inputString) {
    const words = inputString.split(' ');

    const processWord = (word: string) => {
      let capitalCount = 0;
      let modifiedWord = '';

      for (let i = 0; i < word.length; i++) {
        if (word[i] === word[i].toUpperCase()) {
          capitalCount++;

          if (capitalCount <= 4) {
            modifiedWord += word[i];
          } else {
            modifiedWord += word[i].toLowerCase();
          }
        } else {
          modifiedWord += word[i].toLowerCase();
        }
      }

      return modifiedWord;
    };

    const processedWords = words.map(processWord);

    const result = processedWords.join(' ').replace(/  +/g, ' ');

    return result;
  }

  return '';
}

const formatNumber = (number: string) => number?.toString().split('').filter((item: string) => +item >= 1).join('') || '0'

const checkVal = (val: any) => val !== null && val !== undefined && val !== ''

const isRegion = (kato: number) => kato.toString()[4] === '0' && kato.toString()[5] === '0'

export const getPopulationDemandTotal = (item: IPopulationDemand) => {
  const newConstructionRequirement = item.newConstructionRequirement ? +item.newConstructionRequirement : 0;
  const constructionRepairRequirement = item.constructionRepairRequirement ? +item.constructionRepairRequirement : 0;
  const normalConstruction = item.normalConstruction ? +item.normalConstruction : 0;
  return newConstructionRequirement + constructionRepairRequirement + normalConstruction;
}

const getSelfSufficiencyPercentage = (item: IProductSufficiencyItem) => {
  const production = item.annualProductProduction ? item.annualProductProduction : 0;
  const consumption = item.annualConsumption ? item.annualConsumption : 0;
  return production === 0 || consumption === 0 ? 0 : ((production / consumption) * 100).toFixed(1);
};

interface IBudgetEditables {
  curBudgetMonthlyPlan: number,
  curBudgetMonthlyFact: number,
  devBudgetMonthlyPlan: number,
  devBudgetMonthlyFact: number,
  curBudgetAnnualPlan: number,
  devBudgetAnnualPlan: number,
}

const getBudgetTotals = (item: IBudgetItem, key: keyof IBudgetItem): number | string => {
  const obj: IBudgetEditables = {
    curBudgetMonthlyPlan: item.curBudgetMonthlyPlan || 0,
    curBudgetMonthlyFact: item.curBudgetMonthlyFact || 0,
    devBudgetMonthlyPlan: item.devBudgetMonthlyPlan || 0,
    devBudgetMonthlyFact: item.devBudgetMonthlyFact || 0,
    curBudgetAnnualPlan: item.curBudgetAnnualPlan || 0,
    devBudgetAnnualPlan: item.devBudgetAnnualPlan || 0,
  }

  if (key === 'totalBudgetMonthlyProcessing') {
    return +getBudgetTotals(item, 'totalBudgetMonthlyPlan') === 0 || +getBudgetTotals(item, 'totalBudgetMonthlyFact') === 0 ? 0 : ((+getBudgetTotals(item, 'totalBudgetMonthlyFact') / +getBudgetTotals(item, 'totalBudgetMonthlyPlan')) * 100).toFixed(1);
  } else if (key === 'curBudgetMonthlyProcessing') {
    return obj.curBudgetMonthlyPlan === 0 || obj.curBudgetMonthlyFact === 0 ? 0 : ((obj.curBudgetMonthlyFact / obj.curBudgetMonthlyPlan) * 100).toFixed(1);
  } else if (key === 'devBudgetMonthlyProcessing') {
    return obj.devBudgetMonthlyPlan === 0 || obj.devBudgetMonthlyFact === 0 ? 0 : ((obj.devBudgetMonthlyFact / obj.devBudgetMonthlyPlan) * 100).toFixed(1);
  } else if (key === 'curBudgetAnnualProcessing') {
    return obj.curBudgetMonthlyFact === 0 || obj.curBudgetAnnualPlan === 0 ? 0 : ((obj.curBudgetMonthlyFact / obj.curBudgetAnnualPlan) * 100).toFixed(1);
  } else if (key === 'totalBudgetAnnualProcessing') {
    return +getBudgetTotals(item, 'totalBudgetMonthlyFact') === 0 || +getBudgetTotals(item, 'totalBudgetAnnualPlan') === 0 ? 0 : ((+getBudgetTotals(item, 'totalBudgetMonthlyFact') / +getBudgetTotals(item, 'totalBudgetAnnualPlan')) * 100).toFixed(1);
  } else if (key === 'devBudgetAnnualProcessing') {
    return obj.devBudgetMonthlyFact === 0 || obj.devBudgetAnnualPlan === 0 ? 0 : ((obj.devBudgetMonthlyFact / obj.devBudgetAnnualPlan) * 100).toFixed(1);
  } else if (key === 'totalBudgetMonthlyPlan') {
    return +obj.curBudgetMonthlyPlan + +obj.devBudgetMonthlyPlan;
  } else if (key === 'totalBudgetMonthlyFact') {
    return +obj.curBudgetMonthlyFact + +obj.devBudgetMonthlyFact;
  } else if (key === 'totalBudgetAnnualPlan') {
    return +obj.curBudgetAnnualPlan + +obj.devBudgetAnnualPlan;
  }

  return 0
}

const getDevelopmentPercentage = (items: IInvestmentProject[]) => {
  const current = items.reduce((acc, item) => acc + item.currentPrice, 0);
  const total = items.reduce((acc, item) => acc + item.totalPrice, 0);
  return total === 0 ? 0 : ((current / total) * 100).toFixed(1);
}

const calculateRoadSharePercentage = (val: number, total: number) => {
  const totalVal = total ? total : 0;
  const curr = val ? +val : 0;
  return totalVal === 0 ? 0 : ((curr / totalVal) * 100).toFixed(1);
};


const getObjectTotals = (items: any, key: string): string => {
  const list = items;
  if (list.length > 0) {
    if (key.toLowerCase().includes('percentage')) {
      return ((+getObjectTotals(list, key.replace('Percentage', 'Km')) / +getObjectTotals(list, 'totalKm')) * 100).toFixed(2);
    } else if (key.toLowerCase().includes('fine')) {
      if (key.toLowerCase().includes('prev')) {
        return (+getObjectTotals(list, 'prevYearGoodConditionKm') + +getObjectTotals(list, 'prevYearSatisfactoryConditionKm')).toFixed(2);
      } else {
        return (+getObjectTotals(list, 'goodConditionKm') + +getObjectTotals(list, 'satisfactoryConditionKm')).toFixed(2);
      }
    }
    else {
      return list.reduce((acc: number, item: any) => acc + +item[key], 0).toFixed(2);
    }
  }
  return '';
};

const getRegionTotals = (items: any, key: string): string => {
  const list = items;
  if (list.length > 0) {
    if (key.toLowerCase().includes('percentage')) {
      return ((+getRegionTotals(list, key.replace('Percentage', 'Km')) / +getRegionTotals(list, 'totalKm')) * 100).toFixed(2);
    } else {
      return list.reduce((acc: number, item: any) => acc + +getObjectTotals(item.roadsList, key), 0).toFixed(2);
    }
  }
  return ''
}


const getLastWeekdayDate = () => {
  const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

  const today = new Date();
  let lastWeekday = today;

  if (today.getDay() === 0) {
    lastWeekday.setDate(today.getDate() - 2);
  } else if (today.getDay() === 6) {
    lastWeekday.setDate(today.getDate() - 1);
  }

  const day = lastWeekday.getDate().toString().padStart(2, '0');
  const month = months[lastWeekday.getMonth()];
  const year = lastWeekday.getFullYear();

  return `${day} ${month} ${year} г.`;
};

const getIncomePercentage = (item: IDistrictIncomeList, key: string) => {
  const periodFact = item.periodFact || 0;
  const periodPlan = item.periodPlan || 0;
  const annualFact = item.annualFact || 0;
  const annualPlan = item.annualPlan || 0;

  if (key === 'periodImplementation') {
    return periodFact === 0 || periodPlan === 0 ? 0 : ((periodFact / periodPlan) * 100).toFixed(1);
  } else if (key === 'annualImplementation') {
    return annualFact === 0 || annualPlan === 0 ? 0 : ((annualFact / annualPlan) * 100).toFixed(1);
  }
}

const handleGoUp = (wrapperRef: any) => {
  if (wrapperRef && wrapperRef.current) {
    wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

const updateKatoAccessList = (katoAccessList: number[], kato: string, navigate: any, setRegions: (region: any) => void, setRegion: (region: any) => void, setSnps: (region: any) => void, setSnp: (region: any) => void) => {
  if (katoAccessList?.length && kato) {
    if (!katoAccessList.includes(+kato) && !katoAccessList.includes(0) && !katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4)))) {
      navigate('/admin/snp')
    }
    const snpItem: { kato: number, ppKato: number } = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpItem.kato;
    getSettlementTree().then((res: any) => {
      getRegionList().then((regionRes: any) => {
        const withChildren = (Object.keys(res.data)
          .map((item: any) => ({
            key: item, children: Object.keys(res.data[item])
              .map((key: string) => {
                const { pnameKz, pnameRu, pkato } = res.data[item][key][0]
                return ({ value: pkato, label: pnameRu, labelKz: pnameKz })
              })
          })))

        const regions = withChildren.map((elem: any) => ({ ...elem, ...regionRes.find((item: any) => [item.nameRu, item.nameKz].includes(elem.key)) }))
        const filtered = katoAccessList.includes(0)
          ? regions
            .map((item: any) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz, ...item }))
          : regions
            .map((item: any) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz, ...item }))
            .filter((item) => katoAccessList.some(kato => kato.toString().startsWith(item.kato.toString().substring(0, 4))))

        setRegions(filtered)

        const filteredSnps = katoAccessList.includes(0)
          ? filtered[0].children
          : filtered[0].children.filter((item: any) => katoAccessList.includes(+item.kato));

        setSnps(filteredSnps);

        if (val) {
          const found = regions.find((item: any) => item.children.find((item: any) => +item.value === +val)) || regions.find((item: any) => +item.kato === +val);
          if (found) {
            setRegion(+found.value || +found.kato);

            const filteredSnps = katoAccessList.includes(0)
              ? found.children
              : found.children.filter((item: any) => katoAccessList.includes(+item.value) || katoAccessList.some(item => kato.toString().startsWith(item.toString().substring(0, 4))));
            setSnps(filteredSnps);
            setSnp(+val);
          }
        }
      })
    });
  }
}

const currentYear = new Date().getFullYear();

const years = [currentYear - 1, currentYear]
const getYearVal = (year: number) => year === currentYear ? 'currentYearVal' : 'previousYearVal'

const findIndexByKey = (key: string, basicStats: any[]) => {
  const [type, subType] = key.split('-');

  const index = basicStats?.findIndex((item: any) => item.type === type && item.subType === subType);

  return index;
}

const getIncreaseVal = (stat: any, basicStats: any[]) => {
  const item = basicStats?.find((item: any) => item.type === stat.type && item.subType === stat.subType);

  const current = item.currentYearVal ? +item.currentYearVal : 0;
  const previous = item.previousYearVal ? +item.previousYearVal : 0;
  if (current === 0 || previous === 0) return 0;

  return ['SER', 'MCB', 'EMPLOYMENT', 'LIVESTOCK'].includes(item.type) ? (((current - previous) / previous) * 100).toFixed(1) : item.type === 'BUDGET' ? ((current / previous) * 100).toFixed(2) : (current - previous).toFixed(2);
}

const months = [
  'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];

const investProjectFields = [
  {
    name: 'totalPrice',
    type: 'number',
  },
  {
    name: 'currentPrice',
    type: 'number',
  },
  {
    name: 'currentYearTotalSum',
    type: 'number',
  },
  {
    name: 'developmentShare',
    type: 'number',
  },
  {
    name: 'city',
    type: 'text',
  },
  {
    name: 'realizationDate',
    type: 'text',
  },
  {
    name: 'quarter',
    type: 'select',
  }
]

const ruToEnDemands: any = {
  'Здравоохранение': 'health',
  'ИКИ': 'iki',
  'Дороги': 'road',
  'Образование': 'education',
  'Связь': 'connection',
  'Культура и спорт': 'culture'
}
const defaultPopulationDemandStats = ['health', 'iki', 'road', 'education', 'connection', 'culture']

const OBLAST_KATO = 110000000;

const getPopulationDetails = (stats: IPopulationDetails, key: string) => {
  const femaleCount = stats.femaleCount ? +stats.femaleCount : 0;
  const maleCount = stats.maleCount ? +stats.maleCount : 0;

  const immigrationCount = stats.immigrationCount ? +stats.immigrationCount : 0;
  const emigrationCount = stats.emigrationCount ? +stats.emigrationCount : 0;

  const populationEmploymentCount = stats.populationEmploymentCount ? +stats.populationEmploymentCount : 0;

  if (key === 'populationCount') {
    return femaleCount + maleCount;
  } else if (key === 'saldoMigration') {
    return immigrationCount - emigrationCount;
  } else if (key === 'economicallyActivePopulation') {
    return femaleCount === 0 || maleCount === 0 || populationEmploymentCount === 0 ? 0 : ((populationEmploymentCount / (femaleCount + maleCount)) * 100).toFixed(1);
  }

  return 0;
}

const getTaxValue = (data: ITaxStat, key: keyof ITaxStat): number => {
  const taxRevenuesFact = data.taxRevenuesFact ? +data.taxRevenuesFact : 0;
  const nonTaxRevenuesFact = data.nonTaxRevenuesFact ? +data.nonTaxRevenuesFact : 0;
  const fixedAssetsSaleFact = data.fixedAssetsSaleFact ? +data.fixedAssetsSaleFact : 0;

  const taxRevenuesPlan = data.taxRevenuesPlan ? +data.taxRevenuesPlan : 0;
  const nonTaxRevenuesPlan = data.nonTaxRevenuesPlan ? +data.nonTaxRevenuesPlan : 0;
  const fixedAssetsSalePlan = data.fixedAssetsSalePlan ? +data.fixedAssetsSalePlan : 0;

  const taxRevenuesAnnual = data.taxRevenuesAnnual ? +data.taxRevenuesAnnual : 0;
  const nonTaxRevenuesAnnual = data.nonTaxRevenuesAnnual ? +data.nonTaxRevenuesAnnual : 0;
  const fixedAssetsSaleAnnual = data.fixedAssetsSaleAnnual ? +data.fixedAssetsSaleAnnual : 0;

  if (key === 'fact') {
    return +(taxRevenuesFact + nonTaxRevenuesFact + fixedAssetsSaleFact).toFixed(1);
  } else if (key === 'plan') {
    return +(taxRevenuesPlan + nonTaxRevenuesPlan + fixedAssetsSalePlan).toFixed(1);
  } else if (key === 'annual') {
    return +(taxRevenuesAnnual + nonTaxRevenuesAnnual + fixedAssetsSaleAnnual).toFixed(1);
  } else if (key === 'planPercent') {
    return +getTaxValue(data, 'fact') === 0 || +getTaxValue(data, 'plan') === 0 ? 0 : +((+getTaxValue(data, 'fact') / +getTaxValue(data, 'plan')) * 100).toFixed(1);
  } else if (key === 'annualPercent') {
    return +getTaxValue(data, 'fact') === 0 || +getTaxValue(data, 'annual') === 0 ? 0 : +((+getTaxValue(data, 'fact') / +getTaxValue(data, 'annual')) * 100).toFixed(1);
  } else if (key === 'taxRevenuesPlanPercent') {
    return taxRevenuesFact === 0 || taxRevenuesPlan === 0 ? 0 : +((taxRevenuesFact / taxRevenuesPlan) * 100).toFixed(1);
  } else if (key === 'nonTaxRevenuesPlanPercent') {
    return nonTaxRevenuesFact === 0 || nonTaxRevenuesPlan === 0 ? 0 : +((nonTaxRevenuesFact / nonTaxRevenuesPlan) * 100).toFixed(1);
  } else if (key === 'fixedAssetsSalePlanPercent') {
    return fixedAssetsSaleFact === 0 || fixedAssetsSalePlan === 0 ? 0 : +((fixedAssetsSaleFact / fixedAssetsSalePlan) * 100).toFixed(1);
  } else if (key === 'taxRevenuesAnnualPercent') {
    return taxRevenuesFact === 0 || taxRevenuesAnnual === 0 ? 0 : +((taxRevenuesFact / taxRevenuesAnnual) * 100).toFixed(1);
  } else if (key === 'nonTaxRevenuesAnnualPercent') {
    return nonTaxRevenuesFact === 0 || nonTaxRevenuesAnnual === 0 ? 0 : +((nonTaxRevenuesFact / nonTaxRevenuesAnnual) * 100).toFixed(1);
  } else if (key === 'fixedAssetsSaleAnnualPercent') {
    return fixedAssetsSaleFact === 0 || fixedAssetsSaleAnnual === 0 ? 0 : +((fixedAssetsSaleFact / fixedAssetsSaleAnnual) * 100).toFixed(1);
  }

  return 0;
}

const quarters = ['I', 'II', 'III', 'IV'];

export {
  formatDate,
  t,
  getLang,
  capitalize,
  lowerAndTrim,
  keepCapitalized,
  formatNumber,
  checkVal,
  isRegion,
  getSelfSufficiencyPercentage,
  getBudgetTotals,
  getDevelopmentPercentage,
  calculateRoadSharePercentage,
  getObjectTotals,
  getRegionTotals,
  getLastWeekdayDate,
  getIncomePercentage,
  handleGoUp,
  updateKatoAccessList,
  years,
  getYearVal,
  findIndexByKey,
  currentYear,
  getIncreaseVal,
  months,
  investProjectFields,
  ruToEnDemands,
  defaultPopulationDemandStats,
  OBLAST_KATO,
  getPopulationDetails,
  getTaxValue,
  quarters
}