import React from 'react'
import { BasicStatsItem } from '../index.styles';

const InfrastuctureFullScreen = () => {
  return (
    <BasicStatsItem style={{
      width: '100%',
      height: '100%',
    }}>
      <iframe title="PSD iframe" src="https://app.powerbi.com/view?r=eyJrIjoiZTRiMzgzMWMtNTE5ZS00YWNmLWIwY2YtNDE5ZWFmZjQxYzhmIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9" />
    </BasicStatsItem>
  )
}

export default InfrastuctureFullScreen