import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem, FullScreenTable, } from '../index.styles'
import { useTranslation } from 'react-i18next';
import { IProductSufficiencyItem } from '../../../interfaces/snp.interface';
import { getLang, } from '../../../utils/helpers.utils';
import { SortIcon } from '../../../assets/icons';

interface IProps {
  data: IProductSufficiencyItem[];
}

const keys = [
  "productName",
  "annualConsumptionPerPerson",
  "annualConsumption",
  "annualProductProduction",
  "selfSufficiencyPercentage",
]

const FoodContainer: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const [sorted, setSorted] = useState<IProductSufficiencyItem[]>(data);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';

    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    const sortedData = [...data].sort((a: IProductSufficiencyItem, b: IProductSufficiencyItem) => {
      if (key === 'productName') {
        return direction === 'asc'
          ? a[`productName${getLang()}`].localeCompare(b[`productName${getLang()}`])
          : b[`productName${getLang()}`].localeCompare(a[`productName${getLang()}`]);
      } else if (key === 'selfSufficiencyPercentage') {
        return direction === 'asc'
          ? +(a.selfSufficiencyPercentage) - +(b.selfSufficiencyPercentage)
          : +(b.selfSufficiencyPercentage) - +(a.selfSufficiencyPercentage);
      }

      const valueA = +(a[key as keyof IProductSufficiencyItem] || 0);
      const valueB = +(b[key as keyof IProductSufficiencyItem] || 0);
      return direction === 'asc' ? valueA - valueB : valueB - valueA;
    });

    setSorted(sortedData);
    setSortConfig({ key, direction });
  };
  useEffect(() => {
    setSorted(data);
  }, [data]);

  return (
    sorted && <BasicStatsItem>
      <div className="body" style={{ padding: 0 }}>
        <FullScreenTable>
          <thead>
            <tr>
              <td>№</td>
              {keys.map((key: string, index: number) => (
                <td key={index}>
                  <div className="td-flex">
                    <div>{t(`productSelfSufficiencyStats.${key}`)}</div>
                    <SortIcon height={10} onClick={() => handleSort(key)} />
                  </div>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {sorted.map((item: IProductSufficiencyItem, index: number) => (
              <tr key={index}>
                <td>{index + 1}</td>
                {keys.map((key: string, index: number) => (
                  <td key={index}>
                    {key === 'productName'
                      ? item[`productName${getLang()}`]
                      : key === 'selfSufficiencyPercentage'
                        ? `${+item[key as keyof IProductSufficiencyItem]}%`
                        : `${+item[key as keyof IProductSufficiencyItem] ? item[key as keyof IProductSufficiencyItem].toLocaleString() : '-'}`
                    }
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </FullScreenTable>
      </div>
    </BasicStatsItem >
  )
}

export default FoodContainer