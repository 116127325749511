import axios from "axios";
import { IGeneralInfo, IProject } from "../interfaces/snp.interface";

export const getGeneralInfo = (kato: number): Promise<IGeneralInfo> => {
  return axios.get(`/api/public/general?kato=${kato}`).then(res => res.data.data)
}

export const getProjectList = (type: string, kato: number) => {
  return axios.get(`/api/public/snp/infrastructure/list?kato=${kato}&type=${type.toUpperCase().replace('-', '_')}`).then(res => res.data.list)
}

export const getProject = (type: string, id: number): Promise<{ infrastructureEntity: IProject, images: any }> => {
  return axios.get(`/api/public/snp/infrastructure/${id}?type=${type.toUpperCase().replace('-', '_')}`).then(res => res.data.data)
}
