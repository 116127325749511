import React, { useEffect, useState } from 'react'
import LeftMenu from '../../components/left-menu/left-menu.component'
import RightMenu from '../../components/right-menu/right-menu.component'
import styled from 'styled-components'
import { getMarkers, getRegionList, getSettlementTreeV2 } from '../../requests/kato.request'
import { aktobeDistricts } from '../../constants/snp.constant'
import { useNavigate, useParams } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';

import { IMarker, IRegion } from '../../interfaces/snp.interface'
import GoogleMapComponent from "../../components/google-map/google-map.component";
import { useKeycloak } from "@react-keycloak/web";
import { OBLAST_KATO } from '../../utils/helpers.utils'

const HomeWrapper = styled.div`
  display: flex;
  height: 100vh;
  gap: 5rem;
  overflow: hidden;
  
  .absolute {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  };
`
const Home = () => {
  const { kato } = useParams();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<IRegion>();
  const [selectedMarker, setSelectedMarker] = useState<IMarker | null>(null);
  const [showLeftMenu, setShowLeftMenu] = useState<boolean>(true)

  const toggleShowLeftMenu = () => {
    setShowLeftMenu(!showLeftMenu);
  }

  useEffect(() => {
    if (kato && +kato === OBLAST_KATO) {
      setSelectedRegion({ kato: OBLAST_KATO, nameRu: 'Акмолинская область', nameKz: 'Ақмола облысы', coordinates: [0, 0] });
    } else {
      getRegionList().then((res) => {
        const list = res.filter((item) => !['Рудный Г.А.'].includes(item.nameRu))
          .map((item) => ({ ...aktobeDistricts.find(district => district.kato === item.kato), ...item, }));
        if (kato) {
          if (list.map(item => item.kato).includes(+kato)) {
            setSelectedRegion(list.find(item => item.kato === +kato));
            setSelectedMarker(null);
          } else {
            const foundRegionByMarker = list.find(item => item.kato.toString().slice(0, 4) === kato.slice(0, 4));
            if (foundRegionByMarker) {
              setSelectedRegion(foundRegionByMarker);
              getSettlementTreeV2().then((res: any) => {
                const foundRegion = (res.list.find((item: any) => +item.kato === +foundRegionByMarker.kato));
                const foundDistrict = foundRegion.children.find((item: any) => item.kato === +kato);
                setSelectedMarker(foundDistrict);
              })
            }
          }
        } else {
          setSelectedRegion({ kato: OBLAST_KATO, nameRu: 'Акмолинская область', nameKz: 'Ақмола облысы', coordinates: [0, 0] });
        }
      });
    }

  }, [kato]);

  useEffect(() => {
    if (!kato) {
      getMarkers(0).then((res) => {
        setMarkers(res);
        navigate(`/${OBLAST_KATO}`)
        setSelectedRegion({ kato: OBLAST_KATO, nameRu: 'Акмолинская область', nameKz: 'Ақмола облысы', coordinates: [0, 0] });
      });
    }
  }, []);

  return (
    <APIProvider apiKey={'AIzaSyBY8p3nROyRu1bh9hExu8uQXKsa_F_mIjI'} language={'ru'} libraries={['places']}>
      <HomeWrapper>
        <LeftMenu
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
          selectedRegion={selectedRegion!}
          setSelectedRegion={setSelectedRegion}
          selectedMarker={selectedMarker!}
          setSelectedMarker={setSelectedMarker}
          markers={markers}
          keycloak={keycloak}
          showLeftMenu={showLeftMenu}
        />
        <RightMenu
          selectedLink={selectedLink}
          setSelectedLink={setSelectedLink}
          selectedMarker={selectedMarker}
          selectedRegion={selectedRegion}
          toggleShowLeftMenu={toggleShowLeftMenu}
          showLeftMenu={showLeftMenu}
          setShowLeftMenu={setShowLeftMenu}
        />

        <GoogleMapComponent
          setSelectedRegion={setSelectedRegion}
          setSelectedMarker={setSelectedMarker}
        />

      </HomeWrapper>
    </APIProvider>
  )
}

export default Home