import styled from "styled-components";

export const ScreeningItem = styled.div<{ height?: string }>`
  padding: 0.625rem;
  background: var(--bg-color);
  border: 1px solid var(--borderGray);
  border-radius: 0.625rem;
  max-height: ${({ height }) => height};
  height: ${({ height }) => height};

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;

    &__item {
      display: flex;
      gap: 0.625rem;
      padding: 0.625rem 0;
      border-bottom: 1px solid var(--borderGray); 
      align-items: center;
      flex: 1;
      justify-content: space-between;
    }

    .increase {
      display: flex;
      gap: 0.625rem;
      align-items: baseline;

      > div:first-child {
        display: flex;
        gap: 0.3125rem;
        align-items: baseline; 
      }
    }

    .percent-value {
      min-width: 3.75rem;
      text-align: right;
    }

    .icon-container {
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }

    .icon {
      width: 1.4375rem;
      height: 1.4375rem;
      background: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg,
      img {
        width: 1.4375rem;
        height: 1.4375rem;
      }
    }
  }

  .line-chart-container {
    flex: 1;
    max-height: 100%;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    width: 100%;
    align-items: center;
  }

  #legend>ul {
  display: flex;
  justify-content: center;
}

#legend li {
  cursor: pointer;
  margin: 0px 10px;
  display: flex;
}

#legend li span {
  padding-left: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

`

export const ScreeningColumn = styled.div<{ maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  gap: 1.75px;
  flex: 0.33;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  overflow: hidden;
`

export const Cards = styled.div`
  height: 42%;
  display: flex;
  gap: 0.3125rem;
  flex-direction: column;

  .card {
    padding: 0.5rem;
    background: var(--bg-color);
    border: 1px solid var(--borderGray);
    border-radius: 0.625rem;
  }

  .row {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    &__header {
      display: flex;
      justify-content: space-between;
      text-align: center;
      white-space: nowrap;

      > div:first-child {
        text-align: left;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.3125rem;
      justify-items: right;

      .tal {
        justify-self: left;
      }

      .increase {
        display: flex;
        gap: 0.3125rem;
        align-items: baseline;
      }
    }
  }

  .grid.screening-grid { 
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    gap: 3px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      align-items: center;
      justify-content: center;
    }

    .value {
      display: flex;
      gap: 0.25rem;
      align-items: baseline;
    }

    .percents {
      display: flex;
      justify-content: space-between;
    }
  }
`