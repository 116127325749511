import axios from "axios";
import { IAuthResponse } from "../interfaces/auth.interface";
import {api} from "../pages";

export const getToken = (refreshToken: string): Promise<IAuthResponse> => {
  return axios.post('/api/auth/refresh', { refreshToken }).then(res => res.data);
};

export const login = (login: string, password: string): Promise<IAuthResponse> => {
  return axios.post('/api/auth', { login, password }).then(res => res.data);
}

export const getUserInfo = (): Promise<{[key: string]: any}> => {
  return api.get("/user/userinfo").then(res => res);
}