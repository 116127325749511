import React, { FC, useState } from 'react'
import styled from 'styled-components'
import Modal from '../modal-component';
import { ZoomableImageContainer } from '../right-menu/index.styles';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Expand, PhotoLeft, PhotoRight } from '../../assets/icons';
import { getImageUrl } from '../admin-page/general-info.component';

const PhotoSwiperContainer = styled.div`
  max-width: 320px;
  overflow: hidden;
  position: relative;
  padding-bottom: 5px;
`;

const PhotoSlider = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  gap: 5px;
`;

const Photo = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 10px;
  cursor: pointer;
`;

const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #333;
  z-index: 1000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const PrevButton = styled(ScrollButton)`
  left: 5px;
`;

const NextButton = styled(ScrollButton)`
  right: 5px;
`;

interface IProps {
  photos: any[]
}

const PhotoSwiper: FC<IProps> = ({ photos }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedPhoto, setSelectedPhoto] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => Math.min(photos.length - 5, prevIndex + 1));
  };

  if (!photos || photos.length === 0) {
    return <></>
  }

  return (
    <>
      <PhotoSwiperContainer>
        <PrevButton onClick={handlePrevClick}><PhotoLeft /></PrevButton>
        <NextButton onClick={handleNextClick}><PhotoRight /></NextButton>
        <PhotoSlider style={{ transform: `translateX(-${currentIndex * (80 + 5)}px)` }}>
          {photos.map((photo, index) => (
            <Photo key={index} src={getImageUrl(photo)} alt={`photo-${index + 1}`} onClick={() => {
              setIsOpen(true)
              setSelectedPhoto(index)
            }} />
          ))}
        </PhotoSlider>
      </PhotoSwiperContainer>

      {selectedPhoto !== null
        && <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} hasBg={false}>
          <ZoomableImageContainer>
            <TransformWrapper
              initialScale={1}
              initialPositionX={0}
              initialPositionY={0}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <div className="tools">
                    <button className='zoom' onClick={() => zoomIn()}>+</button>
                    <button className='zoom' onClick={() => zoomOut()}>-</button>
                    <button className='zoom' onClick={() => resetTransform()}><Expand /> </button>
                  </div>
                  {selectedPhoto && <TransformComponent>
                    <img src={getImageUrl(photos[selectedPhoto])} alt="object" />
                  </TransformComponent>}
                </>
              )}
            </TransformWrapper>
          </ZoomableImageContainer>
        </Modal>
      }
    </>


  )
}

export default PhotoSwiper