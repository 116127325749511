import React, { FC, useContext } from 'react'
import { BasicStatsItem } from './index.styles'
import { Text } from '../text.component';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getLang, getPopulationDemandTotal } from '../../utils/helpers.utils';
import { IPopulationDemand } from '../../interfaces/snp.interface';
import { NewTab } from '../../assets/icons';
import ThemeContext, { Theme } from '../../contexts/theme.context';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels, ...registerables);

interface IProps {
  data: any;
  kato: string;
}

const PopulationDemand: FC<IProps> = ({ data, kato }) => {
  const { theme } = useContext(ThemeContext)

  const handleLinkClick = () => {
    window.open(`https://aktobe.csi.kz/#/snp?region=${kato}&year=2023`, '_blank');
  }

  return (
    data && <BasicStatsItem id="demand">
      <div className="title">
        <div className="with-icon">
          <Text color='var(--text-color)' fontWeight={700} fontSize='0.75rem' lineHeight='0.87875rem'>Потребности <br /> населения СНП </Text>
          <NewTab fill={theme === Theme.DARK ? 'white' : ''} width={14} height={14} onClick={handleLinkClick} />
        </div>
        <span>ед.</span>
      </div>
      <div className="body with-table">
        <table>
          <thead>
            <tr>
              <td></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='#9ED564' lineHeight='1'>в норме</Text></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='#DE9F55' lineHeight='1'>нужен <br /> ремонт </Text></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='#D76C6C' lineHeight='1'>нужен новый <br />объект </Text></td>
              <td> <Text className='centered-header' fontSize='0.625rem' color='var(--total)' lineHeight='1'>всего </Text></td>
            </tr>
          </thead>
          <tbody>
            {
              data.slice(0, 6).map((item: IPopulationDemand) => (
                <tr className="table-row" key={item.nameRu}>
                  <td>
                    <Text className='subType' fontSize='0.75rem' lineHeight="0.625rem" color='var(--text-color)' fontWeight={400}>
                      {item[`name${getLang()}`]}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='#9ED564' fontWeight={700}>
                      {item.normalConstruction}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='#DE9F55' fontWeight={700}>
                      {item.constructionRepairRequirement}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='#D76C6C' fontWeight={700}>
                      {item.newConstructionRequirement}
                    </Text>
                  </td>
                  <td>
                    <Text className='currentYearVal centered' lineHeight="0.625rem" fontSize='0.875rem' color='var(--total)' fontWeight={700}>
                      {getPopulationDemandTotal(item)}
                    </Text>
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>
    </BasicStatsItem >
  )
}

export default PopulationDemand