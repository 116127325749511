import React, { FC, useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BasicStatsItem } from './index.styles';
import { Text } from '../text.component';
import { useTranslation } from 'react-i18next';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Expand, ExpandWhite } from '../../assets/icons';
import ThemeContext, { Theme } from '../../contexts/theme.context';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

interface IProps {
  chartInfo: {
    data: any;
    options: any;
  };
  handleFullScreen: (obj: any) => void;
}

const PopulationDynamic: FC<IProps> = ({ chartInfo, handleFullScreen }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext)
  const [currYear, setCurrYear] = useState(1000);

  useEffect(() => {
    if (chartInfo) {
      setCurrYear(chartInfo.data.datasets[0].data[3]);
    }
  }, [chartInfo]);
  return (
    <BasicStatsItem id="dynamic">
      <div className="title">
        <div className="left">
          <Text color='var(--text-color)' fontWeight={700} fontSize='0.75rem'>{t(`population-dynamic.title`)}</Text>
        </div>
        <div className="right">
          <span className="unit-container">
            <span>{currYear >= 1000 ? 'тыс. чел' : 'чел'}</span>
            {
              theme === Theme.LIGHT
                ? <Expand onClick={() => handleFullScreen({ isOpen: true, type: 'population-dynamic' })} width={12} height={12} />
                : <ExpandWhite onClick={() => handleFullScreen({ isOpen: true, type: 'population-dynamic' })} width={12} height={12} />
            }
          </span>
        </div>
      </div>
      <div className="body">
        <div className="line-chart-container">
          {chartInfo && <Line data={chartInfo.data} options={chartInfo.options} plugins={[ChartDataLabels]} />}
        </div>
      </div>
    </BasicStatsItem >
  )
};

export default PopulationDynamic;
